<template lang="html">
  <div class="meeting-disclosure" v-click-outside="close">
    <template v-if="$resize && $mq.above(500)">
      <mp-header :header="label"></mp-header>
      <slot></slot>
    </template>
    <template v-else>
      <div class="toggle" :class="{'is-open':open}" @click="open = !open">
        <mp-header :header="label" valign="middle" :stack="false">
          <plus-icon class="plus" />
          <minus-icon class="minus" />
        </mp-header>
      </div>
      <div class="main" ref="main"><slot></slot></div>
    </template>
  </div>
</template>

<script>
// import ClickOutside from 'click-outside'
import ClickOutside from 'vue-click-outside'
import PlusIcon from '@svg/meeting/plus.svg'
import MinusIcon from '@svg/meeting/minus.svg'

export default {
  props: {
    label: String,
    arrowDirection: {
      type: String,
      default: 'down'
    }
  },
  data () {
    return {
      open: false,
      clickOutside: null
    }
  },
  computed: {
    main () {
      return this.$refs.main
    }
  },
  methods: {
    close (e) {
      // e.preventDefault()
      // e.stopImmediatePropagation()
      this.open = false
    }
  },
  directives: {
    ClickOutside
  },
  components: {
    PlusIcon,
    MinusIcon
  },
  watch: {
    open (val) {
      if (val === true) {
        TweenLite.set(this.main, { height: 'auto', opacity: 1 })
  			TweenLite.from(this.main, .4, { height: 0, opacity: 0, ease: Power4.easeOut })
      } else {
        TweenLite.to(this.main, .2, { height: 0, opacity: 0, ease: Power4.easeOut })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/members/_variables.scss';

.toggle {
  cursor: pointer;
  user-select: none;
}

.toggle .plus,
.toggle .minus {
  width: 9px;
  height: 9px;
}

.toggle .minus {
  display: none;
}

.toggle.is-open {
  .plus {
    display: none;
  }

  .minus {
    display: block;
  }
}

.main {
  height: 0;
  // overflow: hidden;
  opacity: 0;
}
// Spacer
.main::after {
  content: '';
  display: block;
  width: 100%;
  height: 50px;
  background-color: transparent;
  pointer-events: none;
  user-select: none;
}
.label {
  position: relative;
  top: 2px;
}

.mp-header {
  border-bottom-color: $colorGreyLight !important;
}
</style>
