<template lang="html">
  <div class="field">
    <label class="label" v-if="!/^checkbox/.test(type)">
      {{ label }}
      <span v-if="saveable" class="g100-button--text" :class="{ 'is-loading': saving }" @click="save">Save</span>
    </label>

    <div v-if="type === 'radio-bool'" class="field has-addons is-columns-x2">
      <div class="control">
        <label class="is-flexible is-fullwidth" :class="[buttonClass, {'is-selected': value === true}]">
          <input type="radio" :name="name" @input="onInput" :value="true"> Yes
        </label>
      </div>
      <div class="control">
        <label class="is-flexible is-fullwidth" :class="[buttonClass, {'is-selected': value === false}]">
          <input type="radio" :name="name" @input="onInput" :value="false"> No
        </label>
      </div>
    </div>

    <div v-else class="control">
      <textarea v-if="type === 'textarea'" ref="input" :rows="rows" :cols="cols"  :class="inputClass" :value="value" @input="onInput" @blur="onBlur" :name="name"></textarea>

      <label v-else-if="type === 'checkbox'" class="checkbox">
        <input type="checkbox" ref="input" :value="value" @input="onInput" @blur="onBlur" :name="name">
        <span class="upcase">{{ label }}</span>
      </label>

      <label v-else-if="type === 'checkbox-bool'" class="checkbox">
        <input type="checkbox" ref="input" @input="onInput" @blur="onBlur" :name="name" :checked="value">
        <span class="upcase">{{ label }}</span>
      </label>

      <div v-else-if="type === 'password'">
        <input ref="input" :autofocus="autofocus" :class="inputClass" :type="type" :value="value" @input="onInput" @blur="onBlur" :name="name">
        <password v-if="passwordStrength" v-model="value" :strength-meter-only="true" @feedback="showFeedback" />
        <p
          v-if="passwordFeedback.warning !== '' || passwordFeedback.suggestions.length"
          class="help"
        >
          {{ passwordFeedback.warning || passwordFeedback.suggestions.join('. ') }}
        </p>
      </div>

      <input v-else ref="input" :autofocus="autofocus" :class="inputClass" :type="type" :value="value" @input="onInput" @blur="onBlur" :name="name" :autocomplete="autocomplete">
    </div>

    <p v-if="error" class="help">{{ error }}</p>
  </div>
</template>

<script>
import autosize from 'autosize'
import uuid from 'uuid/v4'
import Password from 'vue-password-strength-meter'

export default {
  components: {
    Password
  },
  inject: ['$validator'],
  $_veeValidate: {
    name () {
      return this.name
    },
    value () {
      return this.value
    }
  },
  props: {
    buttonClass: {
      type: String,
      default: 'g100-button'
    },
    rows: {
      type: Number,
      default: 8
    },
    cols: {
      type: Number,
      default: 80
    },
    value: [String, Boolean],
    label: String,
    autocomplete: String,
    autofocus: {
      type: Boolean,
      default: false
    },
    passwordStrength: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: uuid()
    },
    type: {
      type: String,
      default: 'text'
    },
    inlineSave: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      changed: false,
      saving: false,
      passwordFeedback: {
        warning: '',
        suggestions: []
      }
    }
  },
  computed: {
    saveable () {
      return this.changed && this.inlineSave
    },
    inputClass () {
      return ['input', { 'is-danger': !!this.error }]
    },
    error () {
      return this.errors.first(this.name)
    }
  },
  methods: {
    showFeedback(feedback) {
      this.passwordFeedback = feedback
    },
    save () {
      this.saving = true
      this.$emit('save')
      setTimeout(() => {
        this.changed = false
        this.saving = false
      }, 300)
    },
    onBlur () {
      this.$emit('blur')
    },
    onInput (e) {
      if (this.changed === false) {
        this.changed = true
      }

      let value = e.target.value

      if (this.type === 'checkbox-bool') {
        value = e.target.checked
      }

      if (value === 'true') { value = true }
      if (value === 'false') { value = false }

      this.$emit('input', value)
    },
    focus () {
      this.$refs.input.focus()
    }
  },
  mounted () {
    autosize(this.$refs.input)
  }
}
</script>

<style lang="scss" scoped>
.g100-button input,
.g100-button--bordered input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  opacity: 0;
  cursor: pointer;
}
</style>
