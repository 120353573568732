<template lang="html">
  <header class="mp-header" :class="cssClass" :style="rootStyle">
    <h1 class="header" :class="{'has-supporting': !!$slots.default}">{{ header }}</h1>
    <div class="supporting" :style="supportingStyle" v-if="!!$slots.default">
      <slot></slot>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    customClass: String,
    supportingStyle: {
      type: String,
      default: ''
    },
    header: String,
    marginTop: {
      type: Number,
      default: 0
    },
    marginBottom: {
      type: Number,
      default: 30
    },
    valign: {
      type: String,
      default: 'bottom'
    },
    stack: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    cssClass () {
      let classes = []
      if (this.customClass) {
        classes.push(this.customClass)
      }
      if (this.valign === 'middle') {
        classes.push('valign-middle')
      }
      if (!this.stack) {
        classes.push('nostack')
      }
      return classes
    },
    rootStyle () {
      return {
        marginTop: `${this.marginTop}px`,
        marginBottom: `${this.marginBottom}px`
      }
    }
  },
  mounted () {
    EQCSS.apply()
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/members/_variables.scss';

.mp-header {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid $colorGreyLightish;

  @media (min-width: 600px) {
    text-align: left;
  }
}

.mp-header.valign-middle {
  align-items: center;
}

.mp-header.margin-bottom {
  margin-bottom: 30px;
}

.mp-grid-x3 + .mp-header {
  margin-top: 60px;
}

.header {
  margin: 0 0 -6px;
  font-size: 22px;
  letter-spacing: 0.6px;
  line-height: 32px;
  // flex: 1 1 auto;
  flex: 1 1 auto;
  font-family: $fontAvenirBook;
  font-weight: normal;
}

.supporting {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: flex-end;
  padding-left: 30px;
}

.supporting > * {
  flex: 0 0 auto;
}

// .header,
// .supporting {
//   flex: 0 0 100%;
// }

.supporting a {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  white-space: nowrap;
  // padding: 20px 0 .5em;
}
</style>
