<template lang="html">
  <div class="field">
    <template v-if="public">
      <form-field :button-class="buttonClass" v-model="rsvpResponse['First Name']" label="First Name" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Last Name']" label="Last Name" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Title']" label="Title" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Company']" label="Company" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Email Address']" label="Email Address" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Assistant Name']" label="Assistant Name" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Assistant Email']" label="Assistant Email" />
    </template>
    <form-field :button-class="buttonClass" :key="rsvpField.sf_field" v-model="rsvpResponse[rsvpField.sf_field]" v-for="rsvpField in filterBy(rsvpFields, true, 'visible')" :label="rsvpField.label || rsvpField.sf_label" :type="rsvpFieldKindToInputType(rsvpField.kind)" :rows="1" />
    <form-field v-if="plusOne" :button-class="buttonClass" v-model="rsvpResponse['Plus One']" label="Plus One?" type="radio-bool" />
    <template v-if="plusOne && rsvpResponse['Plus One']">
      <div class="field">Please enter the details of your plus one:</div>
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Plus One First Name']" label="First Name" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Plus One Last Name']" label="Last Name" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Plus One Title']" label="Title" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Plus One Company']" label="Company" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Plus One Email Address']" label="Email Address" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Plus One Assistant Name']" label="Assistant Name" />
      <form-field :button-class="buttonClass" v-model="rsvpResponse['Plus One Assistant Email']" label="Assistant Email" />
    </template>
  </div>
</template>

<script>
const G100Members = window.G100Members

export default {
  props: ['meetingInvite','rsvpFields','public','buttonClass', 'plusOne'],
  computed: {
    rsvpResponse () {
      return this.meetingInvite.rsvpResponse
    }
  }
}
</script>

<style lang="scss">
</style>
