<template lang="html">
  <div>
    <nav class="g100-navbar is-bordered is-grey-light">
      <div class="is-big-screen">
        <span :class="['g100-navbar__link', { 'is-active': filterIsActive(null) }]" href="" @click="filter(null)">All</span>
        <span :class="['g100-navbar__link', { 'is-active': filterIsActive(/(G100|TC|NGL|WLA|BE)/) }]" data-dropdown-target="program" aria-haspopup="true" aria-expanded="false">
          <span>Program</span>
          <span class="g100-icon is-small"><span class="icon-drop_arrow"></span></span>
        </span>
        <span :class="['g100-navbar__link', { 'is-active': filterIsActive('Executive Leadership') }]" @click="filter('Executive Leadership')">Executive Leadership</span>
        <span :class="['g100-navbar__link', { 'is-active': filterIsActive('Advisors') }]" @click="filter('Advisors')">Advisors</span>
      </div>
      <div class="is-small-screen">
        <span class="g100-navbar__link" data-dropdown-target="sort-team-members" data-tether-attachment="center" aria-haspopup="true" aria-expanded="false">
          <span>Filter Team</span>
          <span class="g100-icon is-small"><span class="icon-drop_arrow"></span></span>
        </span>
      </div>
    </nav>

    <div class="g100-navbar__dropdown" data-dropdown="program" aria-hidden="true" role="menu">
      <a @click="filter('G100')">G100</a>
      <a @click="filter('NGL')">G100 Next Generation Leadership</a>
      <a @click="filter('TC')">G100 Talent Consortium</a>
      <a @click="filter('WLA')">G100 Women’s Leadership Acceleration</a>
      <!-- <a @click="filter('WLN')">G100 Women’s Leadership Network</a> -->
      <a @click="filter('BE')">G100 BoardExcellence</a>
      <a @click="filter('Mentore')">Mentore</a>
    </div>
    <div class="g100-navbar__dropdown" data-dropdown="sort-team-members" aria-hidden="true" role="menu">
      <a href="">All</a>
      <div class="g100-navbar__dropdown-sub">
        <div class="g100-navbar__dropdown-sub__parent">
          <span>Programs</span>
          <span class="g100-icon is-small"><span class="icon-drop_arrow"></span></span>
        </div>
        <div class="g100-navbar__dropdown-sub__children">
          <a @click="filter(null)">All</a>
          <a @click="filter('G100')">G100</a>
          <a @click="filter('NGL')">G100 Next Generation Leadership</a>
          <a @click="filter('TC')">G100 Talent Consortium</a>
          <a @click="filter('WLA')">G100 Women’s Leadership Accelaration</a>
          <!-- <a @click="filter('WLN')">G100 Women’s Leadership Network</a> -->
          <a @click="filter('BE')">G100 BoardExcellence</a>
          <a @click="filter('Mentore')">Mentore</a>
        </div>
      </div>
      <a@click="filter('EL')">Executive Leadership</a>
      <a @click="filter('WLA')">Partners</a>
    </div>

    <div class="row is-grey-light">
      <div class="g100-l-3col is-centered">
        <team-member :team-member="teamMember" :key="teamMember.id" v-for="teamMember in filteredTeamMembers" />
        <div class="placeholder" v-if="filteredTeamMembers.length === 0">
          <p>We have no team members that match this filter at this time</p>
          <span @click="filter(null)" class="g100-button">View All</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamMember from './TeamMember'
import AOS from 'aos'
import {
  team
} from './queries.gql'

export default {
  name: 'Team',
  props: ['teamMember'],
  components: {
    TeamMember
  },
  mounted () {
    setTimeout(() => {
      initDropdowns(this.$el)
      initDropdownSubs(this.$el)
    }, 1000)
  },
  updated () {
    imgix.init()
    AOS.refresh()
    if (window.lazyload) window.lazyload.update()
  },
  data () {
    return {
      filterVal: null,
      teamMembers: []
    }
  },
  computed: {
    filteredTeamMembers () {
      if (!this.filterVal) {
        return this.teamMembers
      }
      return this.teamMembers.filter(teamMember => {
        return teamMember.groups.indexOf(this.filterVal) >= 0
      })
    }
  },
  methods: {
    filterIsActive (filter) {
      if (filter && filter.test) {
        return filter.test(this.filterVal)
      }
      return this.filterVal == filter
    },
    filter (filter) {
      this.filterVal = filter
    }
  },
  apollo: {
    teamMembers: {
      query: team,
      loadingKey: 'isLoading',
      fetchPolicy: 'network-only',
      update (data) {
        let teamMembers = data.teamMembers.slice()
        // Coerce JSON string into JSON
        teamMembers = teamMembers.map(teamMember => {
          return Object.assign({}, teamMember, {
            // groups: JSON.parse(teamMember.groups),
            // image: JSON.parse(teamMember.image)
          })
        })
        return teamMembers
      }
    }
  }
}
</script>

<style lang="scss">
</style>
