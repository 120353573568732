<template lang="html">
  <div class="content">
    <mp-header header="Previous Meetings">
      <router-link class="upcase" to="/meetings">
          View Upcoming Meetings
        </router-link>
    </mp-header>
    <section
      v-for="year in Object.keys(meetingsGroupedByYear).reverse()"
      :key="year"
      class="mp-card previous-meetings"
    >
      <header>{{ year }}</header>
      <span class="loader" v-if="loading > 0"></span>
      <mp-previous-meeting-card
        v-for="meeting in meetingsGroupedByYear[year]"
        :key="meeting.id"
        :meeting="meeting"
      />
    </section>
  </div>
</template>

<script>
// import flatMap from 'lodash.flatmap'
import MpPreviousMeetingCard from './MpPreviousMeetingCard'
import {
  allMeetings
} from './queries.gql'

export default {
  components: {
    MpPreviousMeetingCard
  },
  data () {
    return {
      allMeetings: {
        edges: []
      },
      loading: 0
    }
  },
  computed: {
    meetingsGroupedByYear () {
      return this.allMeetings.edges.reduce((groups, edge) => {
        const node = edge.node
        const year = node.startDate.split('-')[0]
        groups[year] = groups[year] || []
        groups[year].push(node)
        return groups
      }, {})
    }
  },
  apollo: {
    allMeetings: {
      query: allMeetings,
      loadingKey: 'loading',
      variables: {
        scope: 'archived'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/members/_variables.scss';

.previous-meetings + .previous-meetings {
  margin-top: 30px;
}

.previous-meetings > header {
  padding: 12px 24px 8px;
  border-bottom: 1px solid $colorGreyLight;
  font-size: 22px;
  color: $colorGrey;
  letter-spacing: 0.6px;
}

.previous-meeting {
  outline: solid thin red;
}
</style>
