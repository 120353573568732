<template lang="html">
  <div>
    <a :href="`/programs/${program.slug}`" class="g100-card" data-aos="fade">
      <div class="g100-card__header" v-if="ixLogoPath">
        <div class="g100-card__accent" :style="`background-color:${program.accentColor}`"></div>
        <img :data-ix-path="ixLogoPath" :data-ix-params='ixLogoParams' sizes="(min-width: 700px) 50vw, (min-width: 1000px) 33.33vw, 100vw" alt="" width="100%">
      </div>
      <div>
        <figure class="g100-card__image">
          <img v-if="ixImagePath" :data-ix-path="ixImagePath" :data-ix-params='ixImageParams' sizes="(min-width: 700px) 418px, (min-width: 1000px) 384px, 570px" alt="">
        </figure>
      </div>
      <div class="g100-card__main">
        <div class="g100-tag -multiple">
          <span v-if="program.programTypes.length">
            <span v-for="(programType, index) in program.programTypes" :key="index">{{ programType }}</span>
          </span>
        </div>
        <span class="g100-h4">{{ program.tagline }}</span>
        <div v-html="program.summary"></div>
      </div>
      <div class="g100-card__footer">
        <span class="g100-button--text">More Information</span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Program',
  props: ['program'],
  computed: {
    ixLogoPath () {
      try {
        return this.program.logo.src.replace('https://s3.amazonaws.com/g100/', '')
      } catch (e) {
        return false
      }
    },
    ixImagePath () {
      return this.program.image.src.replace('https://s3.amazonaws.com/g100/', '')
    },
    ixLogoParams () {
      return JSON.stringify({"w":336})
    },
    ixImageParams () {
      return JSON.stringify({"w":384,"h":288,"auto":"format","fit":"crop","crop":"edges"})
    }
  }
}
</script>

<style lang="scss">
</style>
