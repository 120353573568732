<template lang="html">
  <div v-click-outside="close">
    <div class="toggle upcase" :class="{'is-open':open}" @click="open = !open">
      <span class="label">{{ label }}</span>
      <span class="g100-icon is-small"><span class="icon-drop_arrow" :class="{ 'icon-flip-v': arrowDirection === 'up'}"></span></span>
    </div>
    <div class="main" ref="main"><slot></slot></div>
  </div>
</template>

<script>
// import ClickOutside from 'click-outside'
import ClickOutside from 'vue-click-outside'

export default {
  props: {
    label: String,
    arrowDirection: {
      type: String,
      default: 'down'
    }
  },
  data () {
    return {
      open: false,
      clickOutside: null
    }
  },
  computed: {
    main () {
      return this.$refs.main
    }
  },
  methods: {
    close (e) {
      // e.preventDefault()
      // e.stopImmediatePropagation()
      this.open = false
    }
  },
  directives: {
    ClickOutside
  },
  watch: {
    open (val) {
      if (val === true) {
        TweenLite.set(this.main, { height: 'auto', opacity: 1 })
  			TweenLite.from(this.main, .4, { height: 0, opacity: 0, ease: Power4.easeOut })
      } else {
        TweenLite.to(this.main, .2, { height: 0, opacity: 0, ease: Power4.easeOut })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.toggle .g100-icon {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  transition: transform .2s;
  transform: rotate(0);
}
.toggle.is-open .g100-icon {
  transform: rotate(-180deg);
}
.main {
  height: 0;
  overflow: hidden;
  opacity: 0;
}
.label {
  position: relative;
  top: 2px;
}
</style>
