import Tether from "tether";
import ClickOutside from "click-outside";
import elemDataset from "elem-dataset";
import arrayFrom from "array-from";
import gsap from "gsap";

let Dropdowns = {};

Dropdowns._clickOutsides = [];
Dropdowns._tethers = [];

Dropdowns.sizesParser = function(sizes) {
  const regex = /^(\(.+\))(.+)/;
  const parts = sizes.split(",").map((part) => part.trim());

  for (let part of parts) {
    if (regex.test(part)) {
      var matches = regex.exec(part);
      if (window.matchMedia(matches[1]).matches) {
        return matches[2].trim();
      }
    }

    return parts[parts.length - 1];
  }
};

Dropdowns.destroy = function(tether) {
  tether.destroy();
  tether.element.removeAttribute("style");
  this._tethers.slice(this._tethers.indexOf(tether), 1);
};

Dropdowns.destroyAll = function() {
  this._tethers.forEach(function(tether) {
    tether.destroy();
    tether.element.removeAttribute("style");
  });
  this._clickOutsides.forEach((unbind) => unbind());
};

Dropdowns.init = function(el = document) {
  const _this = this;
  // this.destroyAll()

  el.querySelectorAll("[data-dropdown-target]").forEach(function(targetEl) {
    if (!!targetEl.dropdownInitialized) {
      return;
    }

    const clickOutside = ClickOutside(targetEl, function(e) {
      if (!targetEl.tether) {
        return;
      }

      const dropdownIdentifier = elemDataset(this).dropdownTarget;
      const dropdownEl = el.querySelector(
        `[data-dropdown="${dropdownIdentifier}"]`
      );
      targetEl.classList.remove("is-selected");
      targetEl.tether.disable();
      // TODO: This is duplicated below in the click event handler
      TweenLite.to(dropdownEl, 0.2, { minHeight: 0, height: 0, opacity: 0 });
    });

    _this._clickOutsides.push(clickOutside);

    targetEl.addEventListener("click", function(e) {
      e.preventDefault();

      const dropdownIdentifier = elemDataset(this).dropdownTarget;
      const tetherAttachment = Dropdowns.sizesParser(
        elemDataset(this).tetherAttachment || "left"
      );
      const dropdownEl = el.querySelector(
        `[data-dropdown="${dropdownIdentifier}"]`
      );
      const dropdownLinkEls = dropdownEl.querySelectorAll("a");

      if (this.classList.contains("is-selected")) {
        TweenLite.to(dropdownEl, 0.2, { minHeight: 0, height: 0, opacity: 0 });
        this.classList.remove("is-selected");
        if (this.tether) {
          Dropdowns.destroy(this.tether);
        }
        return;
      } else {
        if (this.tether) {
          Dropdowns.destroy(this.tether);
        }
      }

      const tether = new Tether({
        element: dropdownEl,
        target: this,
        attachment: `top ${tetherAttachment}`,
        targetAttachment: `bottom ${tetherAttachment}`,
        constraints: [{ to: "scrollParent", attachment: "together" }],
        forceRoundedValues: true,
        optimizations: {
          moveElement: false,
        },
      });
      this.tether = tether;
      _this._tethers.push(tether);

      this.tether.enable();

      setTimeout(() => {
        const offsetTop = dropdownEl.getBoundingClientRect().top + 15;
        const minWidth = targetEl.getBoundingClientRect().width;
        dropdownEl.style.minWidth = `${minWidth}px`;
        dropdownEl.style.maxWidth = "100vw";
        dropdownEl.scrollTop = 0;

        this.tether.position();

        TweenLite.set(dropdownEl, {
          minWidth,
          minHeight: 96,
          height: "auto",
          opacity: 1,
          maxHeight: `calc(100vh - ${offsetTop}px)`,
        });
        TweenLite.from(dropdownEl, 0.4, {
          height: 0,
          opacity: 0,
          ease: Power4.easeOut,
        });

        // cascade animate items
        gsap.set(dropdownLinkEls, { opacity: 0 });
        gsap.staggerTo(arrayFrom(dropdownLinkEls), 0.4, {
          opacity: 1,
          ease: Power4.easeInOut,
        });
      }, 50);

      this.classList.toggle("is-selected");
    });

    targetEl.dropdownInitialized = true;
  });
};

export default Dropdowns;
