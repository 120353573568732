<template lang="html">
  <form @submit.prevent="validateBeforeSubmit">
    <div class="field has-addons">
      <div class="control is-expanded">
        <input aria-label="Email Address" :class="{ 'input': true, 'is-danger': errors.has('emailAddress') }" type="text" placeholder="Your email" v-model="form.emailAddress" v-validate="'required|email'" name="emailAddress">
      </div>
      <div class="control">
        <button class="g100-button--dark">Subscribe</button>
      </div>
    </div>
    <p class="help is-danger" v-show="errors.has('emailAddress')">{{ errors.first('emailAddress') }}</p>
  </form>
</template>

<script>
import humanizeString from 'humanize-string'
import gql from 'graphql-tag'

export default {
  name: 'SubscribeForm',
  props: ['form'],
  data () {
    return {
      isSubmitting: false
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit()
        }
      })
    },
    submit () {
      this.isSubmitting = true
      this.$apollo.mutate({
        mutation: gql`mutation ($subscriber: SubscriberInput!) {
          createSubscriber(subscriber: $subscriber) {
            id
          }
        }`,
        // Parameters
        variables: {
          subscriber: {
    				emailAddress: this.form.emailAddress,
            listId: 1
          }
        },
      }).then((data) => {
        if (typeof gtag !== 'undefined') {
          gtag('event', 'submit_form', { event_label: 'Subscribe' })
        }
        openDialog(`
      		<h1 class="g100-h1">Thank you for subscribing</h1>
      		<div class="g100-divider"></div>
      		<p>G100 notebook subscribers receive a monthly roundup of noteworthy news, articles, studies, and datasets relevant to the CEO agenda.</p>
      		<div class="g100-divider"></div>
      		<p><span class="g100-button--text" data-close>Continue</span></p>
      	`)
        this.form = {
          ...this.form,
  				emailAddress: ''
        }

        this.isSubmitting = false
      }).catch((error) => {
        this.isSubmitting = false
      })
    },
    humanizedError (attribute) {
      const error = this.errors.first(attribute)
      if (error) {
        return humanizeString(error)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
