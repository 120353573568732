<template lang="html">
  <form @submit.prevent="validateBeforeSubmit">
    <div class="field-group">
      <div class="field">
        <label class="label">Colleague’s First Name</label>
        <div class="control">
          <input :class="{ 'input': true, 'is-danger': errors.has('firstName') }" type="text" v-model="form.firstName" v-validate="'required'" name="firstName">
        </div>
        <p class="help is-danger" v-show="errors.has('firstName')">{{ errors.first('firstName') }}</p>
      </div>
      <div class="field">
        <label class="label">Colleague’s Last Name</label>
        <div class="control">
          <input :class="{ 'input': true, 'is-danger': errors.has('lastName') }" type="text" v-model="form.lastName" v-validate="'required'" name="lastName">
        </div>
        <p class="help is-danger" v-show="errors.has('lastName')">{{ errors.first('lastName') }}</p>
      </div>
    </div>
    <div class="field">
      <label class="label">Colleague’s Email Address</label>
      <div class="control">
        <input :class="{ 'input': true, 'is-danger': errors.has('emailAddress') }" type="text" v-model="form.emailAddress" v-validate="'required|email'" name="emailAddress">
      </div>
      <p class="help is-danger" v-show="errors.has('emailAddress')">{{ errors.first('emailAddress') }}</p>
    </div>
    <div class="field">
      <label class="label">Your Name</label>
      <div class="control">
        <input :class="{ 'input': true, 'is-danger': errors.has('sharerName') }" type="text" v-model="form.sharerName" v-validate="'required'" name="sharerName">
      </div>
      <p class="help is-danger" v-show="errors.has('sharerName')">{{ errors.first('sharerName') }}</p>
    </div>
    <div class="g100-divider"></div>
    <div class="field actions">
      <div>
        <p>Your and your colleague’s information will be kept confidential.</p>
      </div>
      <button type="submit" class="g100-button">Submit</button>
    </div>
    <vue-recaptcha ref="invisibleRecaptcha" :sitekey="sitekey" @verify="onVerify" size="invisible" badge="inline"></vue-recaptcha>
  </form>
</template>

<script>
import humanizeString from 'humanize-string'
import gql from 'graphql-tag'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'ShareForm',
  props: ['form'],
  components: {
    VueRecaptcha
  },
  created () {
    const script = document.createElement('script')
    script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
    document.querySelector('head')
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript)
  },
  data () {
    return {
      isSubmitting: false
    }
  },
  computed: {
    sitekey () {
      let key = '6Le7luceAAAAAE-2pGq-KZn1LP-fOszSaQO2IS7X'
      if (process.env.RAILS_ENV === 'development' || window.location.host === 'g100-staging.herokuapp.com') {
        key = '6Lcpd-ceAAAAAJz43kUBAZSNSOWKBiXhWLHxzZwR'
      }
      return key
    }
  },
  methods: {
    onVerify: function (recaptchaResponse) {
      this.$apollo.mutate({
        mutation: gql`mutation ($shareRequest: ShareRequestInput!) {
          createShareRequest(shareRequest: $shareRequest) {
            id
          }
        }`,
        // Parameters
        variables: {
          shareRequest: {
    				emailAddress: this.form.emailAddress,
    				firstName: this.form.firstName,
    				lastName: this.form.lastName,
            sharerName: this.form.sharerName,
    				path: window.location.pathname,
            recaptchaResponse: recaptchaResponse
          }
        },
      }).then((data) => {
        if (typeof gtag !== 'undefined') {
          gtag('event', 'submit_form', { event_label: 'Share' })
        }
        openDialog(`
      		<h1 class="g100-h1">Thank You</h1>
      		<div class="g100-divider"></div>
      		<p>Thank you for forwarding this page to your colleague. It has been sent to your colleague’s inbox.</p>
      		<div class="g100-divider"></div>
      		<p><span class="g100-button--text" data-close>Continue</span></p>
      	`)
        this.form = {
          ...this.form,
          firstName: '',
  				lastName: '',
  				emailAddress: '',
          sharerName: ''
        }

        this.isSubmitting = false
      }).catch((error) => {
        this.isSubmitting = false
      })
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit()
        }
      })
    },
    submit () {
      this.$refs.invisibleRecaptcha.execute()
      this.isSubmitting = true
    },
    humanizedError (attribute) {
      const error = this.errors.first(attribute)
      if (error) {
        return humanizeString(error)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
