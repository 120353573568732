<template lang="html">
  <div>
    <nav class="g100-navbar is-bordered is-grey-light">
      <div class="is-big-screen">
        <span :class="['g100-navbar__link', { 'is-active': filterIsActive(null) }]" href="" @click="filter(null)">All</span>
        <span :class="['g100-navbar__link', { 'is-active': filterIsActive('Peer to Peer') }]" @click="filter('Peer to Peer')">Peer to Peer</span>
        <span :class="['g100-navbar__link', { 'is-active': filterIsActive('Executive Mentoring') }]" @click="filter('Executive Mentoring')">Executive Mentoring</span>
        <span :class="['g100-navbar__link', { 'is-active': filterIsActive('Diversity and Inclusion Advisory') }]" @click="filter('Diversity and Inclusion Advisory')">Diversity and Inclusion Advisory</span>
      </div>
      <div class="is-small-screen">
        <span class="g100-navbar__link" data-dropdown-target="sort-membership-programs" data-tether-attachment="center" aria-haspopup="true" aria-expanded="false">
          <span>Sort membership programs</span>
          <span class="g100-icon is-small"><span class="icon-drop_arrow"></span></span>
        </span>
      </div>
    </nav>

    <div class="g100-navbar__dropdown" data-dropdown="sort-membership-programs" aria-hidden="true" role="menu">
      <a @click="filter(null)">All</a>
      <a @click="filter('Peer to Peer')">Peer to Peer</a>
      <a @click="filter('Executive Mentoring')">Executive Mentoring</a>
      <a @click="filter('Diversity and Inclusion Advisory')">Diversity and Inclusion Advisory</a>
    </div>

    <div class="row is-grey-light">
      <div class="g100-l-3col is-centered">
        <program :program="program" :key="program.id" v-for="program in filteredPrograms"/>
        <div class="placeholder" v-if="filteredPrograms.length === 0">
          <p>We have no programs that match this filter at this time</p>
          <span @click="filter(null)" class="g100-button">View All</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import Program from './Program'
import AOS from 'aos'

export default {
  name: 'MembershipProgram',
  props: ['program'],
  components: {
    Program
  },
  mounted () {
    setTimeout(() => {
      initDropdowns(this.$el)
      initDropdownSubs(this.$el)
    }, 1000)
  },
  updated () {
    imgix.init()
    AOS.refresh()
    if (window.lazyload) window.lazyload.update()
  },
  data () {
    return {
      filterVal: null,
      programs: []
    }
  },
  computed: {
    filteredPrograms () {
      if (!this.filterVal) {
        return this.programs
      }
      return this.programs.filter(program => {
        return program.programTypes.indexOf(this.filterVal) >= 0
      })
    }
  },
  methods: {
    filterIsActive (filter) {
      if (filter && filter.test) {
        return filter.test(this.filterVal)
      }
      return this.filterVal == filter
    },
    filter (filter) {
      this.filterVal = filter
    }
  },
  apollo: {
    programs: {
      query: gql`{
        programs {
          id
          name
          slug
          accentColor
          logo
          image
          programTypes
          tagline
          summary
        }
      }`,
      loadingKey: 'isLoading',
      fetchPolicy: 'network-only',
      // update (data) {
      //   let programs = data.programs.slice()
      //   // Coerce JSON string into JSON
      //   programs = programs.map(program => {
      //     return Object.assign({}, program, {
      //       // groups: JSON.parse(program.groups),
      //       // image: JSON.parse(program.image)
      //     })
      //   })
      //   return programs
      // }
    }
  }
}
</script>

<style lang="scss">
</style>
