<template lang="html">
  <form @submit.prevent="validateBeforeSubmit" class="g100-l-2col no-spacing-top">
    <div class="primary">
      <div class="field">
        <label class="label">Reason for message</label>
        <div class="control">
          <div class="select is-expanded">
            <select v-model="form.reason">
              <option value="Membership">Membership</option>
              <option value="Program information">Program information</option>
              <option value="Careers">Careers</option>
              <option value="Media request">Media request</option>
              <option value="Speaking opportunities">Speaking opportunities</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Subject</label>
        <div class="control">
          <input :class="{ 'input': true, 'is-danger': errors.has('subject') }" type="text" v-model="form.subject" v-validate="'required'" name="subject">
        </div>
        <p class="help is-danger" v-show="errors.has('subject')">{{ errors.first('subject') }}</p>
      </div>
      <div class="field-group">
        <div class="field">
          <label class="label">First name</label>
          <div class="control">
            <input  :class="{ 'input': true, 'is-danger': errors.has('firstName') }" type="text" v-model="form.firstName" v-validate="'required'" name="firstName">
          </div>
          <p class="help is-danger" v-show="errors.has('firstName')">{{ errors.first('firstName') }}</p>
        </div>
        <div class="field">
          <label class="label">Last name</label>
          <div class="control">
            <input  :class="{ 'input': true, 'is-danger': errors.has('lastName') }" type="text" v-model="form.lastName" v-validate="'required'" name="lastName">
          </div>
          <p class="help is-danger" v-show="errors.has('lastName')">{{ errors.first('lastName') }}</p>
        </div>
      </div>
      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input  :class="{ 'input': true, 'is-danger': errors.has('email') }" type="text" v-model="form.email" v-validate="'required|email'" name="email">
        </div>
        <p class="help is-danger" v-show="errors.has('email')">{{ errors.first('email') }}</p>
      </div>
      <div class="field">
        <label class="label">Company</label>
        <div class="control">
          <input  :class="{ 'input': true, 'is-danger': errors.has('company') }" type="text" v-model="form.company" v-validate="'required'" name="company">
        </div>
        <p class="help is-danger" v-show="errors.has('company')">{{ errors.first('company') }}</p>
      </div>
      <div class="field">
        <label class="label">Title</label>
        <div class="control">
          <input :class="{ 'input': true, 'is-danger': errors.has('title') }" type="text" v-model="form.title" v-validate="'required'" name="title">
        </div>
        <p class="help is-danger" v-show="errors.has('title')">{{ errors.first('title') }}</p>
      </div>
    </div>
    <div class="secondary">
      <div class="field">
        <label class="label">Message</label>
        <div class="control">
          <textarea data-gramm_editor="false" :class="{ 'input': true, 'is-danger': errors.has('message') }" rows="11" v-model="form.message" v-validate="'required'" name="message"></textarea>
        </div>
        <p class="help is-danger" v-show="errors.has('message')">{{ errors.first('message') }}</p>
      </div>
      <div class="field actions">
        <div>
          <p>A copy of this message will be sent to your email</p>
        </div>
        <button type="submit" class="g100-button" :disabled="isSubmitting">Send message</button>
      </div>
      <vue-recaptcha ref="invisibleRecaptcha" :sitekey="sitekey" @verify="onVerify" size="invisible" badge="inline"></vue-recaptcha>
    </div>
  </form>
</template>

<script>
import enquire from './operations.gql'
import humanizeString from 'humanize-string'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'ContactForm',
  props: ['form'],
  components: {
    VueRecaptcha
  },
  created () {
    const script = document.createElement('script')
    script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
    document.querySelector('head')
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(script, firstScript)
  },
  data () {
    return {
      isSubmitting: false
    }
  },
  computed: {
    sitekey () {
      let key = '6Le7luceAAAAAE-2pGq-KZn1LP-fOszSaQO2IS7X'
      if (process.env.RAILS_ENV === 'development' || window.location.host === 'g100-staging.herokuapp.com') {
        key = '6Lcpd-ceAAAAAJz43kUBAZSNSOWKBiXhWLHxzZwR'
      }
      return key
    }
  },
  methods: {
    onVerify: function (recaptchaResponse) {
      this.$apollo.mutate({
        mutation: enquire,
        variables: {
          enquiry: {
    				company: this.form.company,
    				emailAddress: this.form.email,
    				firstName: this.form.firstName,
    				lastName: this.form.lastName,
    				message: this.form.message,
            reason: this.form.reason,
            recaptchaResponse: recaptchaResponse,
    				subject: this.form.subject,
    				title: this.form.title
          }
        },
      }).then((data) => {
        if (typeof gtag !== 'undefined') {
          gtag('event', 'submit_form', { event_label: 'Contact' })
        }
        openDialog(`
      		<h1 class="g100-h1">Thank You</h1>
      		<div class="g100-divider"></div>
      		<p>We will respond to you shortly.</p>
      		<div class="g100-divider"></div>
      		<p><span class="g100-button--text" data-close>Continue</span></p>
      	`)
        this.form = {
          ...this.form,
  				company: '',
  				email: '',
  				firstName: '',
  				lastName: '',
  				message: '',
          reason: 'Membership',
  				subject: '',
  				title: ''
        }
        this.isSubmitting = false
      }).catch((error) => {
        this.isSubmitting = false
      })
    },
    onExpired: function () {
      this.isSubmitting = false
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit()
        }
      })
    },
    submit () {
      this.$refs.invisibleRecaptcha.execute()
      this.isSubmitting = true
    },
    humanizedError (attribute) {
      const error = this.errors.first(attribute)
      if (error) {
        return humanizeString(error)
      }
    }
  }
}
</script>
