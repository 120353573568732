<template lang="html">
  <div>
    <form @submit.prevent="validateBeforeSubmit">
      <div class="field-group">
        <div class="field">
          <label class="label">First Name</label>
          <div class="control">
            <input :class="{ 'input': true, 'is-danger': errors.has('firstName') }" type="text" v-model="form.firstName" v-validate="'required'" name="firstName">
          </div>
          <p class="help is-danger" v-show="errors.has('firstName')">{{ errors.first('firstName') }}</p>
        </div>
        <div class="field">
          <label class="label">Last Name</label>
          <div class="control">
            <input :class="{ 'input': true, 'is-danger': errors.has('lastName') }" type="text" v-model="form.lastName" v-validate="'required'" name="lastName">
          </div>
          <p class="help is-danger" v-show="errors.has('lastName')">{{ errors.first('lastName') }}</p>
        </div>
      </div>
      <div class="field-group">
        <div class="field">
          <label class="label">Title</label>
          <div class="control">
            <input :class="{ 'input': true, 'is-danger': errors.has('title') }" type="text" v-model="form.title" v-validate="'required'" name="title">
          </div>
          <p class="help is-danger" v-show="errors.has('title')">{{ errors.first('title') }}</p>
        </div>
        <div class="field">
          <label class="label">Company</label>
          <div class="control">
            <input :class="{ 'input': true, 'is-danger': errors.has('company') }" type="text" v-model="form.company" v-validate="'required'" name="company">
          </div>
          <p class="help is-danger" v-show="errors.has('company')">{{ errors.first('company') }}</p>
        </div>
      </div>
      <div class="field">
        <label class="label">Email Address</label>
        <div class="control">
          <input :class="{ 'input': true, 'is-danger': errors.has('emailAddress') }" type="text" v-model="form.emailAddress" v-validate="'required|email'" name="emailAddress">
        </div>
        <p class="help is-danger" v-show="errors.has('emailAddress')">{{ humanizedError('emailAddress') }}</p>
      </div>
      <div class="field-group">
        <div class="field">
          <div class="field-group is-vertical">
            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" id="learn-more-checkbox" v-model="form.learnMore" name="learnMore">
                  <span>{{ 'Learn more about membership' }}</span>
                  <!-- <span>{{ learnMoreMessage }}</span> -->
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" v-model="form.subscribe" name="subscribe">
              <span>
                {{ 'Subscribe to the G100 notebook' }}
                <!-- {{ subscribeMessage }} -->
                <!-- ( <a class="g100-link" href="/latest-notebook" target="_blank" rel="noopener">View</a> ) -->
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="g100-divider"></div>
      <div class="field actions">
        <div>
          <p>Your information will be kept confidential and only used to contact you regarding membership. Please reference our privacy policy for more information.</p>
        </div>
        <button type="submit" class="g100-button" :disabled="isSubmitting">Submit</button>
      </div>
      <vue-recaptcha ref="invisibleRecaptcha" :sitekey="sitekey" @verify="onVerify" size="invisible" badge="inline"></vue-recaptcha>
    </form>
  </div>
</template>

<script>
import humanizeString from 'humanize-string'
import gql from 'graphql-tag'
import Cookies from 'cookies-js'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'RegisterForm',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    programName: {
      type: String,
      default: null
    }
  },
  components: {
    VueRecaptcha
  },
  created () {
    const script = document.createElement('script')
    script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
    document.querySelector('head')
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript)
  },
  data () {
    return {
      isSubmitting: false,
      learnMoreMessage: 'Learn more about membership',
      subscribeMessage: 'Subscribe to the G100 notebook'
    }
  },
  computed: {
    sitekey () {
      let key = '6Le7luceAAAAAE-2pGq-KZn1LP-fOszSaQO2IS7X'
      if (process.env.RAILS_ENV === 'development' || window.location.host === 'g100-staging.herokuapp.com') {
        key = '6Lcpd-ceAAAAAJz43kUBAZSNSOWKBiXhWLHxzZwR'
      }
      return key
    },
    isRegisterPage () {
      return window.location.pathname === '/register'
    },
    continueText () {
      if (this.isRegisterPage) {
        return 'Continue to program information'
      }
      return 'Close'
    },
    continueUrl () {
      const redirectToProgram = Cookies.get('redirect_to_program')
      if (redirectToProgram) {
        return `/programs/${redirectToProgram}`
      } else {
        return '/programs/'
      }
    }
  },
  methods: {
    onVerify: function (recaptchaResponse) {
      this.$apollo.mutate({
        mutation: gql`mutation ($memberProspect: MemberProspectInput!) {
          createMemberProspect(memberProspect: $memberProspect) {
            id
          }
        }`,
        // Parameters
        variables: {
          memberProspect: {
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            titleOrRole: this.form.title,
            company: this.form.company,
            emailAddress: this.form.emailAddress,
            recaptchaResponse: recaptchaResponse,
            programName: this.programName || window.programName || null,
            marketingPreferences: [
              {
                "key": "membership_learn",
                "value": this.form.learnMore,
                "message": this.learnMoreMessage
              },
              {
                "key": "newsletter_subscribe",
                "value": this.form.subscribe,
                "message": this.subscribeMessage
              }
            ]
          }
        },
      }).then((data) => {
        if (typeof gtag !== 'undefined') {
          window.gtag('event', 'submit_form', { event_label: 'Register' })
          window.gtag('event', 'generate_lead')
        }
        let html = `
          <h1 class="g100-h1">Thank You</h1>
          <h2 class="g100-h2">We will contact you regarding membership</h2>
          <div class="g100-divider"></div>
          <p>Thank you for signing up to learn more about G100. We have received your submission. We invite you to learn more about G100, our programs, our team, and more.</p>
          <div class="g100-divider"></div>
        `

        if (this.isRegisterPage) {
          html = html + `<p><a class="g100-button--text" href="${this.continueUrl}" data-close>${this.continueText}</a></p>`
        } else {
          html = html + `<p><span class="g100-button--text" data-close>${this.continueText}</a></p>`
        }

        window.openDialog(html)
        this.form = {
          ...this.form,
          firstName: '',
          lastName: '',
          title: '',
          company: '',
          emailAddress: '',
          subscribe: false,
          learnMore: false,
          learnMorePeerLearning: false,
          learnMoreMentoring: false
        }

        this.isSubmitting = false
      }).catch((error) => {
        this.isSubmitting = false
      })
    },
    onExpired: function () {
      this.isSubmitting = false
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit()
        }
      })
    },
    submit () {
      this.$refs.invisibleRecaptcha.execute()
      this.isSubmitting = true
    },
    humanizedError (attribute) {
      const error = this.errors.first(attribute)
      if (error) {
        return humanizeString(error)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
