<template lang="html">
  <div
    class="meeting"
    :class="{
      public: !meeting.program || publicView,
      'skeleton-active': loading
    }"
  >
    <div data-dropdown="calendar-download">
      <a
        v-if="!!meeting.calendarEventUrls.outlook"
        :href="meeting.calendarEventUrls.outlook"
        target="_blank"
      >
        <outlook-icon />
        Outlook
      </a>
      <a
        v-if="!!meeting.calendarEventUrls.google"
        :href="meeting.calendarEventUrls.google"
        target="_blank"
      >
        <google-icon />
        Google
      </a>
      <a
        v-if="!!meeting.calendarEventUrls.ics"
        :href="meeting.calendarEventUrls.ics"
        target="_blank"
      >
        <ics-icon />
        Download iCal/ICS
      </a>
    </div>

    <!-- <div class="loader" v-if="loading"></div> -->
    <div class="meeting-header" :style="headerStyle">
      <div class="g100-l-1col no-spacing-bottom">
        <div class="program-wrapper">
          <span class="program skeleton" v-if="meeting.program">{{
            meeting.program.name
          }}</span>
        </div>
        <span class="title skeleton">{{ meeting.title }}</span>
      </div>
      <div class="meta" v-if="!empty(meeting.event)">
        <div
          v-if="$resize && $mq.above(700)"
          class="g100-l-4col is-divided spacing-top-medium"
        >
          <div>
            <dl class="g100-dl">
              <dt>Dates</dt>
              <dd class="skeleton">
                <date-span :start="meeting.startDate" :end="meeting.endDate" />
              </dd>
            </dl>
          </div>
          <div>
            <dl class="g100-dl">
              <dt>Start Time</dt>
              <dd class="skeleton">{{ humanizedStartTime }}</dd>
            </dl>
          </div>
          <div>
            <dl class="g100-dl">
              <dt>Duration</dt>
              <dd class="skeleton">{{ meeting.duration }}</dd>
            </dl>
          </div>
          <div>
            <dl class="g100-dl">
              <dt>Location</dt>
              <dd class="skeleton">
                <location :meeting="meeting" />
              </dd>
            </dl>
          </div>
        </div>
        <template v-else>
          <div>
            <dl class="g100-dl">
              <dt>Dates</dt>
              <dd><date-span :start="meeting.startDate" :end="meeting.endDate" /></dd>
            </dl>
          </div>
          <div>
            <dl class="g100-dl">
              <dt>Start Time</dt>
              <dd>{{ humanizedStartTime }}</dd>
            </dl>
          </div>
          <div>
            <dl class="g100-dl">
              <dt>Duration</dt>
              <dd>{{ meeting.duration }}</dd>
            </dl>
          </div>
          <div>
            <dl class="g100-dl">
              <dt>Location</dt>
              <dd><location :meeting="meeting" /></dd>
            </dl>
          </div>
        </template>
      </div>
    </div>
    <div class="mp-content mp-layout-ma">
      <div class="mp-layout-ma__main">
        <meeting-disclosure label="Joining" v-if="!!meetingInvite && meetingInvite.status === 'going' && (meeting.digital || meeting.digitalLocation) && meeting.joiningInformation">
          <div class="logistics">
            <div v-html="meeting.joiningInformation"></div>
          </div>
        </meeting-disclosure>
        <meeting-disclosure
          label="Overview"
          v-if="!empty(meeting.overviewHtml)"
        >
          <div v-html="meeting.overviewHtml"></div>
        </meeting-disclosure>
        <meeting-disclosure label="Logistics" v-if="!(meeting.digital || meeting.digitalLocation) && !empty(meeting.venue)">
          <div class="logistics">
            <!-- img
              v-if="locationGmapImageUrl"
              class="logistics__map"
              width="366"
              height="250"
              :src="locationGmapImageUrl"
              alt=""
            / -->
            <template v-if="meeting.venue.address">
              <p><b>Address</b></p>
              <div v-html="nl2br(meeting.venue.address)"></div>
              <p>
                <a :href="locationGmapUrl" target="_blank"
                  >Open in Google Maps</a
                >
              </p>
            </template>
            <template v-if="meeting.venue.contactInformation">
              <p><b>Venue Contact Information</b></p>
              <div v-html="meeting.venue.contactInformation"></div>
            </template>
            <template v-if="meeting.venue.travelInformation">
              <p><b>Travel Information</b></p>
              <div v-html="meeting.venue.travelInformation"></div>
            </template>
          </div>
        </meeting-disclosure>
        <meeting-disclosure label="Hotels" v-if="!(meeting.digital || meeting.digitalLocation) && !empty(meeting.hotels)">
          <div class="hotel" v-for="hotel in meeting.hotels">
            <p>
              <b>{{ hotel.name }}</b
              ><br />
              {{ hotel.address }}
            </p>
            <p>
              <a :href="hotel.website_url" target="_blank" rel="nofollow"
                >View website</a
              ><br />
              <a :href="hotel.google_maps_url" target="_blank" rel="nofollow"
                >Find on Google Maps</a
              ><br />
              <a :href="hotel.phone | telLink">{{ hotel.phone }}</a>
            </p>
            <template
              v-if="
                !!hotel.rates_and_specials && hotel.rates_and_specials !== ''
              "
            >
              <p><b>Rates and Specials</b></p>
              <div v-html="hotel.rates_and_specials"></div>
            </template>
          </div>
        </meeting-disclosure>
        <meeting-disclosure
          label="Speakers"
          v-if="!empty(meeting.event) && !empty(meeting.event.speakers)"
        >
          <div
            class="mp-card member-card"
            :class="{ clickable: !publicView && speaker.member.visible }"
            @click="viewMember(speaker.member)"
            :data-initial="speaker.member.lastNameInitial"
            v-for="(speaker, index) in meeting.event.speakers"
            :key="index"
          >
            <div class="primary">
              <avatar-image
                v-if="speaker.member.avatar.src"
                :size="72"
                :avatar="speaker.member.avatar"
              ></avatar-image>
              <default-avatar-image
                v-else
                :name="speaker.member.name"
                :size="72"
                :shadowed="false"
              ></default-avatar-image>
              <div class="meta">
                <span>{{ speaker.member.name }}</span>
                <span>{{ speaker.member.title }}</span>
                <span>{{ speaker.member.companyName }}</span>
              </div>
            </div>
            <div class="secondary">
              <div
                v-if="speaker.member.bioHtmlTruncated"
                class="clamp"
                data-clamp="40"
                :data-original="speaker.member.bioHtmlTruncated"
                v-html="speaker.member.bioHtmlTruncated"
              ></div>
            </div>
          </div>
        </meeting-disclosure>
        <meeting-disclosure label="Attachments" v-if="!empty(meeting.files)">
          <div class="files" v-if="!!meeting.files.length">
            <div class="g100-button-group">
              <meeting-file
                :file="file"
                v-for="file in meeting.files"
              ></meeting-file>
            </div>
          </div>
        </meeting-disclosure>
        <meeting-disclosure
          label="Pre-Reads and Useful Links"
          v-if="meeting.preReadsAndUsefulLinks.length"
        >
          <prul
            :prul="prul"
            :key="index"
            v-for="(prul, index) in meeting.preReadsAndUsefulLinks"
          ></prul>
        </meeting-disclosure>
        <meeting-disclosure
          label="G100 Content for this Meeting"
          v-if="!publicView && meeting.contentItems.length"
        >
          <div class="mp-grid-x2">
            <mp-content-card
              :item="item"
              v-for="(item, index) in meeting.contentItems"
              :key="index"
            ></mp-content-card>
          </div>
        </meeting-disclosure>
        <meeting-disclosure
          :label="content.header"
          v-for="(content, index) in meeting.additionalContent"
          :key="index"
        >
          <div v-html="content.body"></div>
        </meeting-disclosure>
      </div>

      <div class="mp-layout-ma__aside" v-if="canAttend && meeting.oneClickRsvp">
        <div class="mp-card rsvp">
          <div class="rsvp__main">
            <div class="g100-h3">RSVP to this meeting</div>
            <p v-if="meeting.digital || meeting.digitalLocation">
              To rsvp for a digital meeting, please click ‘Attending’ below to 
              receive a confirmation email with meeting information.
            </p>
            <p v-else>
              Please see below for all of the necessary information for your
              next G100 meeting. We hope your schedule allows you to
              join us.
            </p>
            <div class="field has-addons is-columns-x2">
              <div class="control is-flexible">
                <g100-button
                  label="Attending"
                  :flexible="true"
                  :fullwidth="true"
                  :selected="meetingInvite.status === 'going'"
                  @click="oneClickGoing"
                />
              </div>
              <div class="control is-flexible">
                <g100-button
                  label="Not attending"
                  :flexible="true"
                  :fullwidth="true"
                  :selected="meetingInvite.status === 'not_going'"
                  @click="oneClickNotGoing"
                />
              </div>
            </div>
          </div>

          <div
            class="rsvp__calendar"
            v-if="attending && (meeting.digital || meeting.digitalLocation)"
          >
            <span
              class="g100-button--text"
              data-dropdown-target="calendar-download"
            >
              <span>Add to calendar</span>
              <span class="g100-icon is-small"
                ><span class="icon-drop_arrow"></span
              ></span>
            </span>
          </div>

        </div>
      </div>


      <div class="mp-layout-ma__aside" v-if="canAttend && !meeting.oneClickRsvp">
        <div class="mp-card rsvp">
          <div class="rsvp__main">
            <div class="g100-h3">RSVP to this meeting</div>
            <p v-if="meeting.digital || meeting.digitalLocation">
              To rsvp for a digital meeting, please click ‘Attending’ below to 
              receive a confirmation email with meeting information.
            </p>
            <p v-else>
              Please see below for all of the necessary information for your
              next G100 meeting. We hope your schedule allows you to
              join us.
            </p>
            <div class="field has-addons is-columns-x2">
              <div class="control is-flexible">
                <g100-button
                  label="Attending"
                  :flexible="true"
                  :fullwidth="true"
                  :selected="meetingInvite.status === 'going'"
                  @click="meetingInvite.status = 'going'"
                />
              </div>
              <div class="control is-flexible">
                <g100-button
                  label="Not attending"
                  :flexible="true"
                  :fullwidth="true"
                  :selected="meetingInvite.status === 'not_going'"
                  @click="meetingInvite.status = 'not_going'"
                />
              </div>
            </div>
          </div>
          <div
            class="rsvp__data"
            v-if="
              attendanceStatus === 'going' &&
                !!meetingInvite.rsvpResponse &&
                meeting.rsvpFields.length
            "
          >
            <rsvp-fields
              :plus-one="meeting.acceptPlusOnes"
              :public="anyoneCanAttend && publicView"
              :meeting-invite="meetingInvite"
              :rsvp-fields="meeting.rsvpFields"
            ></rsvp-fields>
            <div class="field actions">
              <g100-button
                :label="rsvpButtonLabel"
                :fullwidth="true"
                @click="saveDetails"
                :busy="saveDetailsButtonBusy"
              />
            </div>
          </div>
          <div
            class="rsvp__data"
            v-if="attendanceStatus === 'not_going' && meetingInvite && !meeting.oneClickRsvp"
          >
            <div class="field">
              <label class="label">Reason for unavailability</label>
              <div class="control">
                <div class="select is-expanded">
                  <select v-model="meetingInvite.cancellationReason">
                    <option :value="null" disabled>Please select…</option>
                    <option value="Schedule conflicts - Business"
                      >Schedule Conflict - Business</option
                    >
                    <option
                      value="Schedule conflicts - Business (Customer/Vendor)"
                      >Schedule Conflict - Customer/Vendor</option
                    >
                    <option value="Schedule conflicts - Business (Board)"
                      >Schedule Conflict - Board</option
                    >
                    <option
                      value="Schedule conflict - Other Development Meeting"
                      >Schedule Conflict - Other Development Meeting</option
                    >
                    <option value="Personal Conflicts"
                      >Schedule Conflict - Personal</option
                    >
                    <option value="Weather">Inclement Weather</option>
                    <option value="No Reason Given">Reason not listed</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="field actions">
              <g100-button
                :label="rsvpButtonLabel"
                :fullwidth="true"
                @click="saveDetails"
                :busy="saveDetailsButtonBusy"
              />
            </div>
          </div>

          <div
            class="rsvp__calendar"
            v-if="attending && (meeting.digital || meeting.digitalLocation)"
          >
            <span
              class="g100-button--text"
              data-dropdown-target="calendar-download"
            >
              <span>Add to calendar</span>
              <span class="g100-icon is-small"
                ><span class="icon-drop_arrow"></span
              ></span>
            </span>
          </div>

        </div>
      </div>


<!--
      <div class="mp-layout-ma__aside" v-else-if="!inThePast">
        <mp-header header="Nominate a Member" :margin-bottom="48" />
        <div class="mp-card is-padded">
          <member-nomination-form />
        </div>
      </div>
-->
    </div>


    <div class="mp-layout-ma__aside--ss">
      <disclosure
        label="RSVP to this meeting"
        arrow-direction="up"
        v-if="canAttend"
      >
        <div class="is-dark">
          <div class="rsvp__main">
            <div class="field has-addons is-columns-x2">
              <div class="control">
                <g100-button
                  label="Attending"
                  :bordered="true"
                  :flexible="true"
                  :fullwidth="true"
                  :selected="meetingInvite.status === 'going'"
                  @click="meetingInvite.status = 'going'"
                />
              </div>
              <div class="control">
                <g100-button
                  label="Not attending"
                  :bordered="true"
                  :flexible="true"
                  :fullwidth="true"
                  :selected="meetingInvite.status === 'not_going'"
                  @click="meetingInvite.status = 'not_going'"
                />
              </div>
            </div>
          </div>
          <div class="rsvp__data" v-if="meetingInvite.status === 'going'">
            <rsvp-fields
              :plus-one="meeting.acceptPlusOnes"
              button-class="g100-button--bordered"
              :open="anyoneCanAttend && publicView"
              :meeting-invite="meetingInvite"
              :rsvp-fields="meeting.rsvpFields"
            ></rsvp-fields>
            <div class="field actions">
              <g100-button
                :label="rsvpButtonLabel"
                :fullwidth="true"
                @click="saveDetails"
                :busy="saveDetailsButtonBusy"
                :bordered="true"
              />
            </div>
          </div>
          <div
            class="rsvp__data"
            v-if="attendanceStatus === 'not_going' && meetingInvite"
          >
            <div class="field">
              <label class="label">Reason for unavailability</label>
              <div class="control">
                <div class="select is-expanded">
                  <select v-model="meetingInvite.cancellationReason">
                    <option :value="null" disabled>Please select…</option>
                    <option value="Schedule conflicts - Business"
                      >Schedule Conflict - Business</option
                    >
                    <option
                      value="Schedule conflicts - Business (Customer/Vendor)"
                      >Schedule Conflict - Customer/Vendor</option
                    >
                    <option value="Schedule conflicts - Business (Board)"
                      >Schedule Conflict - Board</option
                    >
                    <option
                      value="Schedule conflict - Other Development Meeting"
                      >Schedule Conflict - Other Development Meeting</option
                    >
                    <option value="Personal Conflicts"
                      >Schedule Conflict - Personal</option
                    >
                    <option value="Weather">Inclement Weather</option>
                    <option value="No Reason Given">Reason not listed</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="field actions">
              <g100-button
                :label="rsvpButtonLabel"
                :fullwidth="true"
                @click="saveDetails"
                :busy="saveDetailsButtonBusy"
              />
            </div>
          </div>
        </div>
      </disclosure>
 
      <disclosure
        label="Nominate a Member"
        arrow-direction="up"
        v-else-if="!inThePast"
      >
        <div class="is-dark">
          <div class="nominate">
            <member-nomination-form />
          </div>
        </div>
      </disclosure>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import h2p from 'html2plaintext'
import moment from 'moment'
import Prul from './Prul'
import contrast from 'contrast'
import shave from 'shave'
import debounce from 'lodash.debounce'
import MeetingDisclosure from './MeetingDisclosure.vue'
import RsvpFields from './RsvpFields.vue'
import empty from 'is-empty'
import OutlookIcon from '@svg/calendar/outlook.svg'
import GoogleIcon from '@svg/calendar/google.svg'
import AppleIcon from '@svg/calendar/apple.svg'
import IcsIcon from '@svg/calendar/ics.svg'
import nl2br from 'nl2br'
import MeetingFile from './MeetingFile'
import MemberNominationForm from '../MemberDirectory/MemberNominationForm'

import {
  meeting,
  meetingInvite
} from './queries.gql'
import {
  prospect,
  rsvp
} from './operations.gql'

window.moment = moment

let saveDetailsButtonLabelDefault = 'RSVP for meeting'
const saveDetailsButtonLabelSuccess = 'RSVP received'
const rsvpSuccessToast = 'Thank you. Your RSVP has been received'

export default {
  props: {
    meetingId: String,
    public: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      // rsvpResponse: {},
      loading: 0,
      going: null,
      goingButtonBusy: false,
      notGoingButtonBusy: false,
      saveDetailsButtonBusy: false,
      saveDetailsButtonLabel: saveDetailsButtonLabelDefault,
      saveDetailsButtonLabelTimeout: null,
      meetingInvite: {
        status: null,
        rsvpResponse: {}
      },
      meeting: {
        title: 'Meeting',
        duration: '1 day',
        location: 'G100 HQ',
        overviewHtml:
          '<p class="skeleton">Nulla voluptate nisi amet Lorem qui mollit amet labore adipisicing fugiat cillum. Duis exercitation adipisicing occaecat ut laborum sint occaecat in deserunt anim minim deserunt ex. Lorem sit ad dolor laboris nisi eu reprehenderit eu ex. Ad consequat elit veniam laboris. Cupidatat mollit ut sunt velit ut pariatur tempor quis. Minim elit laboris culpa sunt Lorem qui laboris ea.</p>',
        program: {
          name: 'Program'
        },
        event: {
          speakers: {}
        },
        contentItems: [],
        preReadsAndUsefulLinks: [],
        programIdentifiers: [],
        hotels: [],
        venue: {},
        calendarEventUrls: {},
        rsvpResponse: {}
      }
    }
  },
  components: {
    MemberNominationForm,
    Prul,
    MeetingDisclosure,
    OutlookIcon,
    GoogleIcon,
    AppleIcon,
    IcsIcon,
    RsvpFields,
    MeetingFile
  },
  filters: {
    telLink (value) {
      if (!value) return ''
      return `tel:${value}`
    }
  },
  computed: {
    rsvpButtonLabel () {
      if (this.meetingInvite.status === null) return 'Foo'
      return this.saveDetailsButtonLabel
    },
    anyoneCanAttend () {
      return this.meeting.meetingType === 'open'
    },
    icsUrl () {
      const authorization = localStorage.getItem('authorization--mp')
      return `${this.meeting.calendarEventUrls.ics}?auth_token=${authorization}`
    },
    humanizedStartDate () {
      return moment(this.meeting.startDate).format('MMMM Do YYYY')
    },
    humanizedEndDate () {
      return moment(this.meeting.endDate).format('MMMM Do YYYY')
    },
    humanizedStartTime () {
      return moment(this.meeting.startTime, 'HH:mm')
        .format('h:mma')
        .replace(':00', '')
        .replace(/^0/, '') + ' (ET)'
    },
    attendanceStatus () {
      return this.meetingInvite.status
    },
    attending () {
      return this.meetingInvite && this.meetingInvite.status === 'going'
    },
    inThePast () {
      if (this.loading > 0) return false

      return this.meeting.archived
    },
    canAttend () {
      if (this.loading > 0) return false

      if (this.inThePast) return false

      if (this.meeting.acceptsRsvps === false) return false

      if (this.anyoneCanAttend) return true

      return !this.empty(this.meetingInvite)
    },
    locationGmapUrl () {
      if (!this.meeting.venue.address) {
        return false
      }
      let latLng = h2p(this.meeting.venue.address)
      // if (this.meeting.venue.lat && this.meeting.venue.lng) {
      //   latLng = [this.meeting.venue.lat, this.meeting.venue.lng].join(',')
      // }
      return `https://www.google.com/maps/search/?api=1&query=${latLng}`
    },
    locationGmapImageUrl () {
      if (!this.meeting.venue.address) {
        return false
      }
      const style =
        '&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=480x360'
      // const latLng = '40.7533639,-73.9754303'
      const latLng = h2p(this.meeting.venue.address)
      const key = 'AIzaSyBkBXT2kHWR_LPZt9jCpbc0kVhHaejFjz4'
      const markers = `size:mid|color:0xa9a9a9|label:|${latLng}`
      return `https://maps.googleapis.com/maps/api/staticmap?center=${latLng}&scale=2&zoom=13&size=366x250&maptype=roadmap&key=${key}&style${style}&markers=${markers}`
    },
    headerStyle () {
      if (this.loading > 0) {
        return {
          color: '#231f20',
          backgroundColor: 'rgba(245,245,245)'
        }
      }

      if (!this.meeting.program.accentColor) {
        return {
          color: '#231f20',
          backgroundColor: '#fff'
        }
      }

      const bgColor = this.meeting.program.accentColor

      return {
        color: contrast(bgColor) === 'light' ? '#231f20' : '#fff',
        backgroundColor: bgColor
      }
    },
    publicView () {
      return typeof this.$store === 'undefined'
    }
  },
  methods: {
    viewMember (member) {
      if (this.publicView) return
      if (!member.visible) return
      this.$router.push({ name: 'member', params: { id: member.id } })
    },
    nl2br (value) {
      return nl2br(value)
    },
    empty (obj) {
      return empty(obj)
    },
    oneClickGoing () {
      this.meetingInvite.status = 'going'
      this.updateInvite()
    },
    oneClickNotGoing () {
      this.meetingInvite.status = 'not_going'
      this.updateInvite()
    },
    saveDetails () {
      if (this.meetingInvite.status === 'not_going' && this.publicView) return

      const rsvpFields = this.meetingInvite.rsvpResponse
      const emailRegex = /.{1,}@.{1,}/

      if (this.publicView) {
        if (!emailRegex.test(rsvpFields['Email Address'])) {
          alert('Please enter a valid email address')
          return false
        }

        if (
          !empty(rsvpFields['Assistant Email']) &&
          !emailRegex.test(rsvpFields['Assistant Email'])
        ) {
          alert('Please enter a valid assistant email address')
          return false
        }
      }

      if (meeting.acceptPlusOnes && rsvpFields['Plus One']) {
        if (!emailRegex.test(rsvpFields['Plus One Email Address'])) {
          alert('Please enter a valid Plus One email address')
          return false
        }

        if (
          !empty(rsvpFields['Plus One Assistant Email']) &&
          !emailRegex.test(rsvpFields['Plus One Assistant Email'])
        ) {
          alert('Please enter a valid Plus One assistant email address')
          return false
        }
      }

      if (this.publicView) {
        this.createAttendeeProspect()
      } else {
        this.updateInvite()
      }
    },
    createAttendeeProspect () {
      this.$apollo
        .mutate({
          mutation: prospect,
          variables: {
            meetingAttendeeProspect: {
              meetingId: this.meeting.id,
              status: this.meetingInvite.status,
              rsvpResponse: this.meetingInvite.rsvpResponse
            }
          }
        })
        .then(() => {
          this.saveDetailsButtonLabel = saveDetailsButtonLabelSuccess
          this.saveDetailsButtonLabelTimeout = setTimeout(() => {
            this.saveDetailsButtonLabel = saveDetailsButtonLabelDefault
          }, 3000)
          if (this.publicView) {
            window.openDialog(`
        		<h1 class="g100-h1">Thank You</h1>
        		<div class="g100-divider"></div>
        		<p>Your RSVP has been received. We will respond to you shortly.</p>
        		<div class="g100-divider"></div>
        		<p><span class="g100-button--text" data-close>Continue</span></p>
        	`)
          } else {
            window.toasted.show(rsvpSuccessToast)
          }
        })
        .catch(error => {
          alert(error)
        })
        .finally(() => {
          this.saveDetailsButtonBusy = false
        })
    },
    updateInvite () {
      const meetingInvite = {
        status: this.meetingInvite.status,
        rsvpResponse: this.meetingInvite.rsvpResponse,
        cancellationReason: this.meetingInvite.cancellationReason
      }
      this.saveDetailsButtonBusy = true
      clearTimeout(this.saveDetailsButtonLabelTimeout)

      if (meetingInvite.status === 'going') {
        saveDetailsButtonLabelDefault = 'Update my RSVP'
      } else if (meetingInvite.status === 'not_going') {
        saveDetailsButtonLabelDefault = 'RSVP for meeting'
      }
      this.saveDetailsButtonLabel = saveDetailsButtonLabelDefault

      this.$apollo.mutate({
        mutation: rsvp,
        variables: {
          meetingInviteId: this.meetingInvite.id,
          meetingInvite
        }
      })
      .then(() => {
        this.saveDetailsButtonLabel = saveDetailsButtonLabelSuccess
        this.saveDetailsButtonLabelTimeout = setTimeout(() => {
          this.saveDetailsButtonLabel = saveDetailsButtonLabelDefault
        }, 3000)
        window.toasted.show(rsvpSuccessToast)
      })
      .catch(error => {
        alert(error)
      })
      .finally(() => {
        this.saveDetailsButtonBusy = false
      })
    },
    clamp: debounce(
      function() {
        if (typeof this.$el.querySelectorAll === 'undefined') {
          return
        }
        this.$el.querySelectorAll('.clamp').forEach(el => {
          el.innerHtml = el.dataset.original
          shave(el, el.dataset.clamp)
        })
      },
      100,
      { leading: true }
    )
  },
  created () {
    // Check if we are a member
    if (
      typeof this.$store === 'undefined' &&
      window.localStorage.getItem('authorization--mp')
    ) {
      const meetingSlug = this.meetingId || this.$route.params.id
      window.location.replace(
        `https://members.g100network.com/meetings/${meetingSlug}`
      )
    }
  },
  mounted () {
    window.Dropdowns.init()
    window.addEventListener('resize', this.clamp)
    this.clamp()
  },
  updated () {
    this.clamp()
    window.Dropdowns.init()
  },
  beforeDestroy () {
    window.Dropdowns.destroyAll()
  },
  apollo: {
    meetingInvite: {
      query: meetingInvite,
      fetchPolicy: 'network-only',
      variables () {
        return {
          meetingId: this.meeting.id
        }
      },
      update (data) {
        if (data.meetingInvite && data.meetingInvite.status === 'going') {
          saveDetailsButtonLabelDefault = 'Update my RSVP'
          this.saveDetailsButtonLabel = saveDetailsButtonLabelDefault
        }
        return cloneDeep(data.meetingInvite)
      },
      skip () {
        return this.public === 1
      }
    },
    meeting: {
      query: meeting,
      fetchPolicy: 'network-only',
      variables () {
        return {
          meetingSlug: this.meetingId || this.$route.params.id
        }
      },
      loadingKey: 'loading',
      update (data) {
        if (!data.meeting) {
          this.$router.replace('/meetings')
        }
        return data.meeting
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/members/_variables.scss';

[data-dropdown-target] {
  user-select: none;
  transition: color 0.2s, box-shadow 0.4s, background-color 0.4s;
  cursor: default;
  border: 1px solid transparent;
  border-bottom: 0;
  padding: 20px 15px 5px;
  padding: 20px 15px 5px;

  &.is-selected::before {
    opacity: 0;
  }
}

[data-dropdown-target='calendar-download'] {
  display: block;
  text-align: center;
  // margin-top: 10px;
  // margin-top: -8px;
  // margin-left: -15px;
}

[data-dropdown-target].is-selected {
  color: $colorBlack;
  background-color: $colorWhite;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  border-color: $colorGreyLight;
}

[data-dropdown-target] > .g100-icon {
  margin-left: 10px;
  transition: transform 0.2s;
}

[data-dropdown-target].is-selected > .g100-icon {
  transform: rotate(-180deg);
}

[data-dropdown] {
  z-index: 100;
  user-select: none;
  white-space: nowrap;
  height: 0px;
  overflow-y: hidden;
  color: $colorBlack;
  background-color: $colorWhite;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.18);
  max-width: 100vw;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  border: 1px solid $colorGreyLight;
  border-top: 0;

  html:not(.is-chrome) & {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
    clip-path: inset(0px -10px -10px -10px);
  }
}

[data-dropdown] a {
  position: relative;
  display: flex;
  width: 100%;
  list-style: none;
  padding: 17px 15px 10px;
  align-items: center;
  // justify-content: space-between;
  font-size: 12px;
  letter-spacing: 2.63px;
  text-transform: uppercase;
  text-decoration: none;
  color: $colorBlack;
  transition: color 0.2s;
  white-space: normal;
  cursor: pointer;

  // @media (min-width: $filterBreakpoint) {
  //   padding: 27px 30px 20px;
  // }

  &:hover {
    color: $colorGrey;
  }
}

[data-dropdown] a > svg {
  margin-right: 1em;
}

[data-dropdown] a + a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  height: 1px;
  background-color: #e3e3e4;

  // @media (min-width: $filterBreakpoint) {
  //   left: 30px;
  //   right: 30px;
  // }
}

[data-dropdown] a > i {
  font-style: normal;

  margin-left: 0.25em;
  text-transform: initial;
  font-size: 15px;
  letter-spacing: 0.027em;
  color: $colorGrey;
}

.meeting {
  background-color: #fff;
  border-bottom: 1px solid $colorGreyLight;
}
.meeting-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;
  transition: background-color 0.3s ease;
}
@media (min-width: 700px) {
  .public .meeting-header {
    border-bottom: solid 1px $colorGreyLight;
  }
}
.program-wrapper {
  display: block;
}
.program {
  font-family: $fontAvenirMedium;
  margin-bottom: 15px;

  @media (min-width: 500px) {
    margin-bottom: 3px;
  }
}
.title {
  font-size: 22px;

  @media (min-width: 500px) {
    font-size: 30px;
  }
}
.g100-l-4col.is-divided > *::before {
  border-color: currentColor;
}
.g100-l-2col > * {
  outline: solid thin red;
}
.rsvp p {
  margin-bottom: 24px;
}
.rsvp .g100-button {
  white-space: nowrap;
}
.mp-header {
  border-bottom-color: $colorGreyLight !important;
}
.hotel {
  clear: both;
}
.hotel + .hotel {
  margin-top: 30px;
}
.logistics__map,
.hotel__photo {
  max-width: 100%;
  height: auto;
  margin-bottom: 30px;

  @media (min-width: 750px) {
    float: left;
    margin: 0 30px 30px 0;
  }
}
.rsvp__main,
.rsvp__data,
.nominate {
  padding: 24px;
}
.rsvp__calendar {
  padding: 12px 24px 24px;
}
.rsvp__main {
  @media (max-width: 1099px) {
    padding-top: 0;
  }
}
.rsvp__data,
.rsvp__calendar {
  border-top: 1px solid $colorGreyLight;
}

.meta {
  width: 100%;
}

@media (max-width: 699px) {
  .meta {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 64px;
    margin-bottom: -64px;
    color: $colorBlack;
    background-color: #fff;
  }

  .meta::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    background-color: #fff;
  }

  .public .meta {
    border-top: 1px solid $colorGreyLight;
  }

  .meta > * {
    position: relative;
    flex: 1 0 auto;
    padding: 18px 32px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      background-color: $colorGreyLight;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: $colorGreyLight;
    }
  }

  @element .meta > * and (min-width: 100%) {
    background-color: red;
  }

  .g100-dl dt {
    color: $colorGrey;
  }
}

.open-close-icon {
  width: 9px;
  height: 9px;
  display: flex;
  // align-items: center;
  // justify-content: center;
}

.open-close-icon > svg {
  display: block;
  width: 9px;
  height: auto;
}
.meeting-disclosure {
  clear: both;
}
@media (min-width: 500px) {
  .meeting-disclosure + .meeting-disclosure {
    margin-top: 50px;
  }
}

@import '~stylesheets/members/_variables.scss';

$filterBreakpoint: 910px;

.clamp {
  position: relative;
  max-height: 40px;
  overflow: hidden;
}

.clamp::before {
  content: '';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 1.2em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%
  );
}

.member-card {
  overflow: hidden;

  @media (min-width: 700px) {
    display: flex;
  }
}
.member-card + .member-card {
  margin-top: 15px;

  @media (min-width: 500px) {
    margin-top: 30px;
  }
}
.member-card .primary,
.member-card .secondary {
  padding: 15px;

  @media (min-width: 500px) {
    padding: 24px;
  }
}
.member-card .secondary .clamp + .g100-button-group {
  margin-top: 15px;
}
.member-card .primary {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  flex-wrap: wrap;
  border-right: 1px solid $colorGreyLight;
  margin-top: -12px;
  margin-bottom: -12px;

  @media (min-width: 700px) {
    width: 320px;
    flex-wrap: nowrap;
  }
}

.member-card .primary > * {
  margin-top: 12px;
  margin-bottom: 12px;
}

.member-card .primary .avatar {
  flex: 0 0 auto;
  margin-right: 20px;
  // margin-bottom: 20px;
}
.member-card .primary .meta {
  font-family: $fontAvenirBook;
  font-size: 18px;
  color: $colorGrey;
  letter-spacing: 0.49px;
}
.member-card .primary .g100-button-group {
  display: flex;
  margin-top: 15px;
  width: calc(100% + 14px);
  justify-content: flex-end;

  @media (min-width: 700px) {
    display: none;
  }
}
.member-card .primary .g100-button-group > * {
  @media (min-width: 399px) {
    width: auto !important;
  }
}
.member-card .secondary {
  display: none;

  @media (min-width: 700px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.member-card .primary .meta > span {
  display: block;
}
.member-card .primary .meta > span:first-child {
  color: $colorBlack;
}

.clamp > * {
  display: none;
}
.clamp > *:first-child {
  display: block;
}
</style>
<style lang="scss">
.logistics p,
.hotel p {
  max-width: none !important;
}
</style>
