<template lang="html">
  <div class="prul" v-if="!empty(prul.links)">
    <span :class="['summary', { 'open' : isOpen }]" @click="toggle">
      <span>{{ prul.content }}</span>
      <span class="g100-icon is-small"><span class="icon-drop_arrow"></span></span>
    </span>
    <div class="details" ref="details">
      <div class="prul-link" :key="index" v-for="(link, index) in prul.links" @click="click(link.url)">
        <span class="prul-link__title upcase b">{{ link.title }}</span>
        <span class="prul-link__attribution">
          <span v-if="link.author" class="author">{{ link.author }}</span><span v-if="link.author && link.publication">, </span><span v-if="link.publication" class="publication">{{ link.publication }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import empty from 'is-empty'

export default {
  props: ['prul'],
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    detailsEl () {
      return this.$refs.details
    }
  },
  methods: {
    empty (obj) {
      return empty(obj)
    },
    click (url) {
      window.open(url)
    },
    toggle () {
      if (this.isOpen) {
        this.close()
      } else {
        this.open()
      }
    },
    close () {
      TweenLite.to(this.detailsEl, .2, { height: 0, opacity: 0 })
      this.isOpen = false
    },
    open () {
      TweenLite.set(this.detailsEl, { height: 'auto', opacity: 1 })
			TweenLite.from(this.detailsEl, .4, { height: 0, opacity: 0, ease: Power4.easeOut })
      this.isOpen = true
    }
  },
  mounted () {
    TweenLite.set(this.detailsEl, { height: 0, opacity: 0 })
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/members/_variables.scss';

.prul {
  padding: 26px 0 24px;
  border-bottom: 1px solid $colorGreyLight;
}
.details {
  overflow: hidden;
  text-decoration: none;
  color: inherit;
}
.details > *:first-child {
  margin-top: 18px;
}
.prul-link {
  margin-top: 20px;
  cursor: pointer;
}
.prul-link > span {
  display: block;
}
.prul-link__title {
  margin-bottom: .5em;
}
.prul-link__attribution {
  color: $colorGrey;
}
.summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  color: $colorGrey;
  letter-spacing: 0.6px;
  outline: none;
  user-select: none;
  cursor: pointer;
}
.summary .g100-icon {
  flex: 0 0 auto;
  transition: transform .2s;
}
.summary.open .g100-icon {
  transform: rotate(-180deg);
}
.author {
  font-family: $fontAvenirMedium;
}
.publication {
  font-style: italic;
}
</style>
