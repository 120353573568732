/* eslint no-console: 0 */

import "@babel/polyfill";
import "es6-promise/auto";
import "unfetch/polyfill";
import Vue from "vue/dist/vue.esm";
import Vue2Filters from "vue2-filters";
import EQCSS from "eqcss";
import MpHeader from "@mp-shared/MpHeader";
import Button from "@shared/Button";
import Disclosure from "@shared/Disclosure";
import MpContentCard from "@mp-shared/MpContentCard";
import FormField from "@mp-shared/FormField";
import DefaultAvatarImage from "@shared/DefaultAvatarImage";
import Dropdowns from "@shared/dropdowns";
import { MediaQueries } from "vue-media-queries";
import VeeValidate from "vee-validate";
import "flickity-imagesloaded";
import Flickity from "flickity";
import { ResizeSensor, ElementQueries } from "css-element-queries";
import Sniffr from "sniffr";
import elemDataset from "elem-dataset";
import arrayFrom from "array-from";
import "nodelist-foreach-polyfill";
import Tether from "tether";
import ClickOutside from "click-outside";
import LazyLoad from "vanilla-lazyload";
import Vex from "vex-js";
import throttle from "throttleit";
import smoothscroll from "smoothscroll-polyfill";
import AOS from "aos";
import "imgix.js";
import Equalizer from "equalizer";
import Stickyfill from "stickyfilljs";
import contrast from "contrast";
import VueApollo from "vue-apollo";
import { apolloProvider } from "../public/apollo";
import { Show as Meeting } from "../members/components/Meetings";
import RegisterForm from "../public/components/RegisterForm";
import RegisterBlock from "../public/components/RegisterBlock";
import Team from "../public/components/Team";
import MembershipPrograms from "../public/components/MembershipPrograms";
import ContactForm from "../public/components/ContactForm";
import ShareForm from "../public/components/ShareForm";
import SubscribeForm from "../public/components/SubscribeForm";

window.EQCSS = EQCSS;
window.Dropdowns = Dropdowns;
const mediaQueries = new MediaQueries();

Vue.use(Vue2Filters);

Vue.component("mp-header", MpHeader);
Vue.component("mp-content-card", MpContentCard);
Vue.component("form-field", FormField);
Vue.component("default-avatar-image", DefaultAvatarImage);
Vue.component("g100-button", Button);
Vue.component("disclosure", Disclosure);

Vue.use(mediaQueries);

Vue.mixin({
  methods: {
    rsvpFieldKindToInputType(kind) {
      switch (kind) {
        case "yes_no":
          return "radio-bool";
        case "text":
          return "textarea";
        default:
          return null;
      }
    },
  },
});

Vue.use(VeeValidate, {
  events: "blur",
  dictionary: {
    en: {
      messages: {
        required: (field) => `Required`,
        email: (field) => `Must be a valid email address`,
      },
    },
  },
});

ElementQueries.listen();

Vex.defaultOptions.className = "vex-theme-plain";

smoothscroll.polyfill();

Vex.registerPlugin(require("vex-dialog"));

window.imgix.config.host = "g100.imgix.net";
window.imgix.config.srcAttribute = "data-src";
window.imgix.config.srcsetAttribute = "data-srcset";
window.imgix.config.sizesAttribute = "sizes";
window.imgix.config.srcInputAttribute = "data-ix-src";
window.imgix.config.pathInputAttribute = "data-ix-path";
window.imgix.config.paramsInputAttribute = "data-ix-params";
window.imgix.config.hostInputAttribute = "data-ix-host";

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add("flickity-resize");
};

Flickity.createMethods.push("_createResizeClass");

const resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove("flickity-resize");
  resize.call(this);
  this.element.classList.add("flickity-resize");
};

let clickOutsides = [];
let tethers = [];

const initCarousels = function() {
  const carousels = document.querySelectorAll(".g100-carousel");
  if (carousels.length === 0) {
    return;
  }
  carousels.forEach(function(carouselEl) {
    const carouselSlidesEl = carouselEl.querySelector(".g100-carousel__slides");
    const carouselPrevious = carouselEl.querySelector(
      ".g100-carousel__previous"
    );
    const carouselNext = carouselEl.querySelector(".g100-carousel__next");
    const flickity = new Flickity(carouselSlidesEl, {
      imagesLoaded: true,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      lazyLoad: 1,
    });
    carouselPrevious.addEventListener("click", function(e) {
      flickity.previous();
    });
    carouselNext.addEventListener("click", function(e) {
      flickity.next();
    });
  });
};

const initDropdowns = function(el = document) {
  el.querySelectorAll("[data-dropdown-target]").forEach(function(targetEl) {
    clickOutsides.push(
      ClickOutside(targetEl, function(e) {
        if (!targetEl.tether) {
          return;
        }

        const dropdownIdentifier = elemDataset(this).dropdownTarget;
        const dropdownEl = el.querySelector(
          `[data-dropdown="${dropdownIdentifier}"]`
        );
        targetEl.classList.remove("is-selected");
        targetEl.tether.destroy();
        window.TweenLite.to(dropdownEl, 0.2, { height: 0, opacity: 0 });
      })
    );

    targetEl.addEventListener("click", function(e) {
      e.preventDefault();
      const dropdownIdentifier = elemDataset(this).dropdownTarget;
      const tetherAttachment = elemDataset(this).tetherAttachment || "left";
      const dropdownEl = el.querySelector(
        `[data-dropdown="${dropdownIdentifier}"]`
      );
      const dropdownLinkEls = dropdownEl.querySelectorAll("a");

      if (this.classList.contains("is-selected")) {
        window.TweenLite.to(dropdownEl, 0.2, { height: 0, opacity: 0 });
        this.tether.destroy();
      } else {
        if (!this.tether) {
          const tether = new Tether({
            element: dropdownEl,
            target: this,
            attachment: `top ${tetherAttachment}`,
            targetAttachment: `bottom ${tetherAttachment}`,
            constraints: [{ to: "scrollParent", attachment: "together" }],
          });
          this.tether = tether;
          tethers.push(tether);
        }
        this.tether.enable();
        this.tether.position();

        window.TweenLite.set(dropdownEl, { height: "auto", opacity: 1 });
        window.TweenLite.from(dropdownEl, 0.4, {
          height: 0,
          opacity: 0,
          ease: window.Power4.easeOut,
        });

        // cascade animate items
        window.gsap.set(dropdownLinkEls, { opacity: 0 });
        window.gsap.staggerTo(arrayFrom(dropdownLinkEls), 0.4, {
          opacity: 1,
          ease: window.Power4.easeInOut,
        });
      }

      this.classList.toggle("is-selected");
    });
  });
};

window.initDropdowns = initDropdowns;

const closeOpenMastheadDropdowns = function({
  success = function() {},
  fail = function() {},
} = {}) {
  return new Promise((resolve, reject) => {
    const openDropdowns = document.querySelectorAll(
      ".g100-masthead__dropdown.is-open"
    );
    if (openDropdowns.length === 0) {
      resolve();
    }

    const tl = new window.TimelineLite({ onComplete: resolve });
    openDropdowns.forEach((dropdownEl) => {
      const dropdownIdentifier = elemDataset(dropdownEl).mastheadDropdown;
      document
        .querySelector(
          `.g100-masthead__link[data-masthead-dropdown-target="${dropdownIdentifier}"]`
        )
        .classList.remove("is-selected");
      tl.to(dropdownEl, 0.2, {
        height: 0,
        onComplete: function() {
          dropdownEl.classList.remove("is-open");
        },
      });
    });
  });
};

const initMastheadDropdowns = function() {
  const mastheadEls = document.querySelectorAll(".g100-masthead");
  if (mastheadEls.length === 0) {
    return;
  }
  mastheadEls.forEach((masthead) => {
    const navBarLinksWithDropdowns = masthead.querySelectorAll(
      ".g100-masthead__link[data-masthead-dropdown-target]"
    );

    navBarLinksWithDropdowns.forEach((el) => {
      el.addEventListener("click", function(e) {
        const navBarLinkEl = this;
        const dropdownIdentifier = elemDataset(navBarLinkEl)
          .mastheadDropdownTarget;
        const selector = `.g100-masthead__dropdown[data-masthead-dropdown="${dropdownIdentifier}"]`;
        const dropdownEl = document.querySelector(selector);

        if (navBarLinkEl.classList.contains("is-selected")) {
          window.TweenLite.to(dropdownEl, 0.2, { height: 0 });
        } else {
          closeOpenMastheadDropdowns().then(function() {
            window.TweenLite.set(dropdownEl, { height: "auto", opacity: 1 });
            window.TweenLite.from(dropdownEl, 0.4, {
              height: 0,
              opacity: 0,
              ease: window.Power4.easeInOut,
            });
          });
        }

        dropdownEl.classList.toggle("is-open");
        navBarLinkEl.classList.toggle("is-selected");
      });
    });
  });
};

const initMastheadDropdownSubNav = function() {
  const parentDropdowns = document.querySelectorAll(
    ".g100-masthead__dropdown li.is-parent"
  );
  parentDropdowns.forEach((el) => {
    const ulEl = el.querySelector("ul");

    el.addEventListener("click", function(e) {
      if (el.classList.contains("is-open")) {
        window.TweenLite.to(ulEl, 0.2, { height: 0, opacity: 0 });
      } else {
        window.TweenLite.set(ulEl, { height: "auto", opacity: 1 });
        window.TweenLite.from(ulEl, 0.4, {
          height: 0,
          opacity: 0,
          ease: window.Power4.easeInOut,
        });
      }
      el.classList.toggle("is-open");
    });
  });
};

const initBackToTop = function() {
  const backToTops = document.querySelectorAll(".g100-back-to-top");
  if (backToTops.length === 0) {
    return;
  }
  backToTops.forEach((backToTop) => {
    backToTop.addEventListener("click", function() {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    });
  });
};

// const initSocialMedia = function () {
//   const linkedInButtonContainer = document.querySelector('[data-social-button="linkedin"]')
//   if (linkedInButtonContainer) {
//     linkedInButtonContainer.innerHTML = `<script src="//platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script>
// <script type="IN/FollowCompany" data-id="2342234"></script>`
//   }
//   const twitterButtonContainer = document.querySelector('[data-social-button="twitter"]')
//   if (twitterButtonContainer) {
//     twitterButtonContainer.innerHTML = `<a href="https://twitter.com/g100network?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @g100network</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`
//   }
// }

const initScrollTo = function() {
  document.querySelectorAll('[href^="#"]').forEach(function(scrollToTarget) {
    // const scrollToId = scrollToTarget.dataset.scrollto
    const scrollToId = scrollToTarget.getAttribute("href").replace("#", "");
    const el = document.querySelector(`[name="${scrollToId}"]`);
    scrollToTarget.addEventListener("click", function(e) {
      e.preventDefault();
      const clientRects = el.getBoundingClientRect();
      el.scrollIntoView({ behavior: "smooth" });
      window.scrollBy({
        left: 0,
        top: clientRects.top - 64,
        behavior: "smooth",
      });
    });
  });
};

const initDropdownSubs = function(el = document) {
  el.querySelectorAll(".g100-navbar__dropdown-sub__parent").forEach(function(
    subParentEl
  ) {
    const subChildrenEl = subParentEl.nextElementSibling;
    subParentEl.addEventListener("click", function(e) {
      e.stopImmediatePropagation();
      if (subParentEl.classList.contains("is-open")) {
        window.TweenLite.to(subChildrenEl, 0.2, { height: 0, opacity: 0 });
      } else {
        window.TweenLite.set(subChildrenEl, { height: "auto", opacity: 1 });
        window.TweenLite.from(subChildrenEl, 0.4, {
          height: 0,
          opacity: 0,
          ease: window.Power4.easeOut,
        });
      }
      subParentEl.classList.toggle("is-open");
    });
  });
};

window.initDropdownSubs = initDropdownSubs;

Vue.use(VueApollo);

const initMeeting = function() {
  const meetingEl = document.getElementById("meeting");
  if (!meetingEl) {
    return;
  }

  // eslint-disable-next-line no-new
  new Vue({
    el: "#meeting",
    apolloProvider,
    components: {
      Meeting,
    },
    mediaQueries,
  });
};

const initTeam = function() {
  const teamEl = document.getElementById("team");
  if (!teamEl) {
    return;
  }

  // eslint-disable-next-line no-new
  new Vue({
    el: "#team",
    apolloProvider,
    components: {
      Team,
    },
  });
};

const initMembershipPrograms = function() {
  const membershipProgramsEl = document.getElementById("membership-programs");
  if (!membershipProgramsEl) {
    return;
  }

  // eslint-disable-next-line no-new
  new Vue({
    el: "#membership-programs",
    apolloProvider,
    components: {
      MembershipPrograms,
    },
  });
};

const initRegsiterForm = function() {
  const registerFormEl = document.getElementById("register-form");
  if (!registerFormEl) {
    return;
  }

  // eslint-disable-next-line no-new
  new Vue({
    el: "#register-form",
    apolloProvider,
    components: {
      RegisterForm,
    },
    data: {
      form: {
        firstName: "",
        lastName: "",
        title: "",
        company: "",
        emailAddress: "",
        subscribe: false,
        learnMore: false,
        learnMorePeerLearning: false,
        learnMoreMentoring: false,
      },
    },
  });
};

const initRegsiterBlock = function() {
  const registerBlockEl = document.getElementById("register-block");
  if (!registerBlockEl) {
    return;
  }

  // eslint-disable-next-line no-new
  new Vue({
    el: "#register-block",
    apolloProvider,
    components: {
      RegisterBlock,
    },
  });
};

const initContactForm = function() {
  const shareFormEl = document.getElementById("contact-form");
  if (!shareFormEl) {
    return;
  }

  // eslint-disable-next-line no-new
  new Vue({
    el: "#contact-form",
    apolloProvider,
    components: {
      ContactForm,
    },
    data: {
      form: {
        reason: "Membership",
        subject: "",
        message: "",
        emailAddress: "",
        firstName: "",
        lastName: "",
        company: "",
        title: "",
      },
    },
  });
};

const initShareForm = function() {
  const shareFormEl = document.getElementById("share-form");
  if (!shareFormEl) {
    return;
  }

  // eslint-disable-next-line no-new
  new Vue({
    el: "#share-form",
    apolloProvider,
    components: {
      ShareForm,
    },
    data: {
      form: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        sharerName: "",
      },
    },
  });
};

const initSubscribeForm = function() {
  const subscribeFormEl = document.getElementById("subscribe-form");
  if (!subscribeFormEl) {
    return;
  }

  // eslint-disable-next-line no-new
  new Vue({
    el: "#subscribe-form",
    apolloProvider,
    components: {
      SubscribeForm,
    },
    data: {
      form: {
        emailAddress: "",
      },
    },
  });
};

window.openDialog = function(content) {
  Vex.open({
    unsafeContent: content,
    showCloseButton: false,
    afterOpen() {
      this.contentEl.querySelectorAll("[data-close]").forEach((el) => {
        el.addEventListener("click", () => this.close());
      });
    },
  });
};

const initExternalLinks = function() {
  for (let link of Array.from(document.links)) {
    if (link.hostname === window.location.hostname) continue;
    if (/^(https?)/.test(link.href)) {
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener");
    }
  }
};

const onDomReady = function() {
  initCarousels();
  // initBuildDebug()
  initMastheadDropdowns();
  initMastheadDropdownSubNav();
  initBackToTop();
  onRender();
  initExternalLinks();

  document.querySelectorAll(".g100-quote").forEach((quote) => {
    const backgroundColor = quote.style.backgroundColor || "#e3e3e4";

    if (contrast(backgroundColor) === "light") {
      quote.style.color = "#000";
    } else {
      quote.style.color = "#fff";
    }
  });

  window.imgix.init();

  const sniffr = new Sniffr();
  sniffr.sniff(navigator.userAgent);
  if (sniffr.browser.name === "chrome") {
    document.documentElement.classList.add("is-chrome");
  }
};

const onRender = function() {
  // initMastheadDropdowns()
  initDropdowns();
  initDropdownSubs();
  // initSocialMedia()
  initScrollTo();
  initMeeting();
  initTeam();
  initRegsiterForm();
  initRegsiterBlock();
  initContactForm();
  initShareForm();
  initSubscribeForm();
  initMembershipPrograms();

  document.querySelectorAll(".g100-box").forEach((box) => {
    const avatars = box.querySelectorAll(".g100-member-avatar");
    Equalizer(avatars).align();
  });

  const elements = document.querySelectorAll(".sticky");
  Stickyfill.add(elements);

  window.setTimeout(function() {
    document.body.classList.remove("is-loading");
    document.body.classList.add("is-loaded");
  }, 100);

  const mastheadEls = document.querySelectorAll(".g100-masthead");
  Stickyfill.add(mastheadEls);
};

const onWindowLoad = function() {
  AOS.init({
    duration: 600,
  });
  // AOS.refresh()

  window.lazyload = new LazyLoad({
    callback_load() {
      AOS.refresh();
    },
  });
};

const onScroll = function() {
  const mastheadEl = document.querySelector(".g100-masthead");
  const backToTopEl = document.querySelector(".g100-back-to-top");
  if (window.scrollY > 104) {
    mastheadEl.classList.add("is-compact");
    backToTopEl.classList.add("is-visible");
  } else {
    mastheadEl.classList.remove("is-compact");
    backToTopEl.classList.remove("is-visible");
  }
};

if (document.readyState === "interactive") {
  onDomReady();
  window.addEventListener("load", onWindowLoad);
} else {
  document.addEventListener("DOMContentLoaded", onDomReady);
  window.addEventListener("load", onWindowLoad);

  // document.addEventListener('turbolinks:render', onRender)
  // document.addEventListener('turbolinks:load', onWindowLoad)
  // document.addEventListener('turbolinks:before-visit', onBeforeVisit)
}

window.addEventListener("scroll", throttle(onScroll, 200));
