<template lang="html">
  <div>
    <div class="content">
      <div class="mp-grid-x2">
        <div>
          <span class="mp-h1">G100 Meetings</span>
          <span class="mp-h2">Bring the outside in</span>
        </div>
        <div>
          <p>G100 meetings feature unique agendas informed by today’s leading business minds. They cover critical, timely issues for executives. Our candid, informed discussions drill down on key topics.</p>
        </div>
      </div>
    </div>

    <div class="mp-divider is-fullwidth"></div>

    <div class="content">
      <mp-header header="Your Upcoming Meetings">
        <router-link class="upcase" to="/meetings/archived">
          View Past Meetings
        </router-link>
      </mp-header>
      <p v-if="!userMeetings.edges.length">There are currently no upcoming meetings for your programs</p>
      <div class="mp-grid-x2" v-if="!!userMeetings.edges.length">
        <mp-meeting-card v-for="edge in userMeetings.edges" :meeting="edge.node" />
      </div>
    </div>

    <div class="mp-container is-white is-fullwidth is-marginless" hidden style="display:none">
      <div class="mp-container__inner">
        <div class="mp-grid-x2">
          <div>
            <span class="mp-h1">Add our events and meetings to your calendar by
    subscribing. Events will be automatically added and updated via our subscription.</span>
          </div>
          <div>
            <p>Subscribe to your calendar and never miss a meeting. This is a one-time download, and will work with any calendar that supports the iCal (.ics) format.</p>
            <span class="g100-button--bordered" data-dropdown-target="calendar-download">
              Subscribe
              <span class="g100-icon is-small"><span class="icon-drop_arrow"></span></span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="mp-divider is-fullwidth"></div>

    <div class="content">
      <mp-header header="All G100 Meetings">
        <span class="upcase" data-dropdown-target="programs" data-tether-attachment="(min-width: 530px) right, center">
          Program
          <span class="g100-icon is-small"><span class="icon-drop_arrow"></span></span>
        </span>
      </mp-header>

      <p v-if="!allMeetings.edges.length">There are currently no upcoming meetings</p>
      <div class="mp-grid-x2" v-if="!!allMeetings.edges.length">
        <mp-meeting-card :key="edge.node.id" v-for="edge in allMeetings.edges" :meeting="edge.node" />
      </div>
    </div>

    <div data-dropdown="programs">
      <a href="" @click.prevent="allMeetingsProgramFilter = null">View All</a>
      <a v-for="program in programs" href="" @click.prevent="allMeetingsProgramFilter = program.id">
        {{ program.name }}
      </a>
    </div>

    <div data-dropdown="calendar-download">
      <a :href="appleCalUrl" target="_blank">
        <outlook-icon />
        Outlook
      </a>
      <a :href="gcalUrl" target="_blank">
        <google-icon />
        Google
      </a>
      <a :href="appleCalUrl" target="_blank">
        <apple-icon />
        Apple
      </a>
      <a href="" @click.prevent="copyIcsUrl">
        <ics-icon />
        Copy iCal/ICS URL
      </a>
    </div>
  </div>
</template>

<script>
import {
  allMeetings,
  programs,
  userMeetings
} from './queries.gql'

import AppleIcon from '@svg/calendar/apple.svg'
import GoogleIcon from '@svg/calendar/google.svg'
import IcsIcon from '@svg/calendar/ics.svg'
import OutlookIcon from '@svg/calendar/outlook.svg'

export default {
  components: {
    AppleIcon,
    GoogleIcon,
    IcsIcon,
    OutlookIcon
  },
  data () {
    return {
      allMeetings: {
        edges: []
      },
      allMeetingsProgramFilter: null,
      programs: [],
      userMeetings: {
        edges: []
      }
    }
  },
  apollo: {
    allMeetings: {
      query: allMeetings,
      fetchPolicy: 'network-only',
      variables () {
        return {
          inProgram: [this.allMeetingsProgramFilter],
          scope: 'upcoming'
        }
      }
    },
    programs: {
      query: programs
    },
    userMeetings: {
      query: userMeetings,
      variables: {
        first: 2,
        scope: 'upcoming'
      }
    }
  },
  mounted () {
    Dropdowns.init()
  },
  methods: {
    copyIcsUrl () {
      this.$copyText(this.icsUrl).then(function (e) {
        toasted.show('Your calendar subscription URL has been copied to your clipboard')
      }, function (e) {
        toasted.show('Sorry, your calendar subscription URL could not be copied to your clipboard')
      })
    }
  },
  computed: {
    appleCalUrl () {
      return this.icsUrl.replace('https://', 'webcal://')
    },
    gcalUrl () {
      return `http://www.google.com/calendar/render?cid=${this.icsUrl.replace('https', 'http')}`
    },
    icsUrl () {
      try {
        return `${G100Members.baseUrl}calendars/${this.$store.state.currentUser.calendarSubscriptionToken}.ics`
      } catch (e) {
        return null
      }
    },
    outlookcomUrl () {
      return `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addsubscription&url=${this.icsUrl}&name=Follow+our+calendar&mkt=en-001`
    },
    programsTetherAttachment () {
      if (window.innerWidth >= 530) {
        return 'right'
      } else {
        return 'center'
      }
    }
  },
  updated () {
    Dropdowns.init()
  },
  beforeDestroy () {
    Dropdowns.destroyAll()
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/members/_variables.scss';

[data-dropdown-target="programs"] {
  user-select: none;
  transition: color .2s, box-shadow .4s, background-color .4s;
  cursor: default;
  border: 1px solid transparent;
  border-bottom: 0;
  padding: 20px 15px 5px;

  &.is-selected::before {
    opacity: 0;
  }
}

[data-dropdown-target="calendar-download"],
[data-dropdown="calendar-download"] {
  width: 240px;
}

[data-dropdown-target].is-selected {
  color: $colorBlack;
  background-color: $colorWhite;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.18);
  // border-color: $colorGreyLight;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-dropdown="calendar-download"] {
  border: 1px solid $colorGreyLight;
  border-top: 0;
}

[data-dropdown-target="calendar-download"].is-selected {
  border-color: $colorGreyLight;
}

[data-dropdown-target] > .g100-icon {
  margin-left: 10px;
  transition: transform .2s;
}

[data-dropdown-target].is-selected > .g100-icon {
  transform: rotate(-180deg);
}

[data-dropdown] {
  z-index: 100;
  user-select: none;
  white-space: nowrap;
  height: 0px;
  overflow-y: hidden;
  color: $colorBlack;
  background-color: $colorWhite;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.18);
  max-width: 100vw;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  // border: 1px solid $colorGreyLight;
  // border-top: 0;

  html:not(.is-chrome) & {
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.18);
    clip-path: inset(0px -10px -10px -10px);
  }
}


[data-dropdown] a {
  position: relative;
  display: flex;
  width: 100%;
  list-style: none;
  padding: 17px 15px 10px;
  align-items: center;
  // justify-content: space-between;
  font-size: 12px;
  letter-spacing: 2.63px;
  text-transform: uppercase;
  text-decoration: none;
  color: $colorBlack;
  transition: color .2s;
  white-space: normal;
  cursor: pointer;

  // @media (min-width: $filterBreakpoint) {
  //   padding: 27px 30px 20px;
  // }

  &:hover {
    color: $colorGrey;
  }
}

[data-dropdown] a > svg {
  margin-right: 1em;
}

[data-dropdown] a + a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  height: 1px;
  background-color: #e3e3e4;

  // @media (min-width: $filterBreakpoint) {
  //   left: 30px;
  //   right: 30px;
  // }
}

[data-dropdown] a > i {
  font-style: normal;

  margin-left: .25em;
  text-transform: initial;
  font-size: 15px;
  letter-spacing: 0.027em;
  color: $colorGrey;
}
</style>
