import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import VueApollo from 'vue-apollo'

const csrfToken = document.getElementsByName('csrf-token')[0].content

const httpLink = new HttpLink({
  // You should use an absolute URL here
  // uri: 'https://g100.dev/graphql'
  uri: '/graphql',
  credentials: 'same-origin',
  headers: {
      'X-CSRF-Token': csrfToken
  }
})

const errorHandler = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    if (typeof cms !== 'undefined') {
      cms.danger(networkError.response.statusText)
    } else {
      alert(networkError.response.statusText)
    }
  } else if (graphQLErrors.length) {
    if (typeof cms !== 'undefined') {
      cms.danger(graphQLErrors[0].message)
    } else {
      alert(graphQLErrors[0].message)
    }
  }
})

const apolloClient = new ApolloClient({
  link: errorHandler.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

export {
  httpLink,
  apolloClient,
  apolloProvider
}
