<template lang="html">
  <div class="mp-previous-meeting">
    <div class="date">
      <mp-dates :start="meeting.startDate" :end="meeting.endDate" />
    </div>
    <div class="header">
      <span class="program" :style="programTextStyle">{{ meeting.program.name }}</span>
      <span class="title">{{ meeting.title }}</span>
      <div class="links">
        <router-link :to="{ name: 'meeting', params: { id: meeting.slug } }" class="upcase">Meeting Details</router-link>
        <router-link :to="{ name: 'content-library-item', params: {id: meeting.playbook.slug } }" class="upcase" v-if="meeting.playbook">Online Playbook</router-link>
        <a class="upcase" :href="meeting.playbookDownloadUrl" target="_blank" v-if="meeting.playbookDownloadUrl">PDF Playbook</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    meeting: {
      type: Object,
      default: {
        program: {}
      }
    }
  },
  computed: {
    programTextStyle () {
      return {
        color: this.meeting.program.accentColor
      }
    },
    startTime () {
      return moment(this.meeting.startTime).format('HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/members/_variables.scss';

.mp-previous-meeting {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 24px;
  border-radius: 2px 2px 0 0;
  margin-bottom: auto;
}

.header::before {
  content: '';
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 0;
  width: 1px;
  background-color: $colorGreyLight;
}

.program {
  display: block;
  font-family: $fontAvenirMedium;
  margin-bottom: 3px;
}

.title {
  font-size: 22px;
  margin-bottom: 3px;
}

.details {
  padding: 15px;

  @media (min-width: 500px) {
    display: flex;
    align-items: center;
    padding: 15px 20px;
  }
}

.date {
  display: flex;
  position: relative;
  width: 140px;
  padding-left: 24px;
  flex: 0 0 auto;

  // @media (min-width: 500px) {
  //   padding-right: 17px;
  // }
}

.more {
  font-family: $fontAvenirMedium;
  color: $colorGrey;
}

.links {
  margin: -5px -10px;
}

.links > * {
  display: inline-block;
  padding: 5px 10px;
  color: $colorGrey;
}
</style>
