<template lang="html">
  <div @click="show" class="g100-card clickable" :class="{ 'clickable': isClickable }" data-aos="fade">
    <div>
      <figure v-if="item.coverImage.src" class="g100-card__image">
        <img :data-ix-path="ixPath" :data-ix-params="ixParams" sizes="(min-width: 700px) 418px, (min-width: 1000px) 384px, 570px" :alt="item.title">
      </figure>
    </div>
    <div class="g100-card__main">
      <div class="g100-tag -multiple" v-if="!!item.itemType" :style="{background: item.accentColor, color: item.textColor}">
        <span>
          <span>{{ item.itemType }}</span>
        </span>
      </div>
      <span class="g100-h3">{{ item.title }}</span>
      <span v-if="item.subtitle" class="subtitle">{{ item.subtitle }}</span>
      <div class="clamp" v-if="item.summary" v-html="item.summary"></div>
    </div>
    <div class="g100-card__footer">
      <span class="g100-button--text">Read More</span>
    </div>
  </div>
</template>

<script>
import AOS from 'aos'

export default {
  props: ['item'],
  computed: {
    // TODO: Implement this
    // NOTE: Should no longer be required as we scope content to the member
    isClickable () {
      return true
    },
    coverImage () {
      return this.item.coverImage
    },
    ixPath () {
      return this.item.coverImage.src.replace('https://s3.amazonaws.com/g100/', '')
    },
    ixParams () {
      return JSON.stringify({"w":384,"h":288,"auto":"format","fit":"crop","crop":"edges"})
    }
  },
  methods: {
    show () {
      this.$router.push({ name: 'content-library-item', params: { id: this.item.slug } })
    }
  },
  mounted () {
    imgix.init()
    AOS.init({
  		duration: 600
  	})
    if (window.lazyload) window.lazyload.update()
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/members/_variables.scss';

.clickable {
  cursor: pointer;
}
.g100-card {
  overflow: visible;
}

.clamp {
  position: relative;
  max-height: 64px;
  overflow: hidden;
}

.clamp::before {
  content: '';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.subtitle {
  margin-top: 0px !important;
  margin-bottom: 25px !important;
  display: block;
  color: $colorGrey;
  margin-bottom: 1em;
}
</style>
