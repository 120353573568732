<template lang="html">
  <div>
    <a class="g100-card" :href="`/team/${teamMember.slug}`" data-aos="fade">
      <div>
        <figure class="g100-card__image" v-if="teamMember.imageWithFallback">
          <img class="loaded" :src="image.src" alt="" v-if="image.storage === 'base64'">
          <img :data-ix-path="ixPath" :data-ix-params="ixParams" sizes="(min-width: 700px) 418px, (min-width: 1000px) 384px, 570px" alt="" v-else>
        </figure>
      </div>
      <div class="g100-card__main">
        <span class="g100-h3">{{ teamMember.name }}</span>
        <span class="g100-h4">{{ teamMember.titleOrRole }}</span>
        <!-- {{ teamMember.groups.join(', ') }} -->
        <div v-html="teamMember.summary"></div>
      </div>
      <div class="g100-card__footer">
        <span class="g100-button--text">More Information</span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'TeamMember',
  props: ['teamMember'],
  computed: {
    image () {
      return this.teamMember.imageWithFallback
    },
    ixPath () {
      return this.image.src.replace('https://s3.amazonaws.com/g100/', '')
    },
    ixParams () {
      return JSON.stringify({"w":384,"h":288,"auto":"format","fit":"crop","crop":"edges"})
    }
  }
}
</script>

<style lang="scss">
</style>
