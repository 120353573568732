<template lang="html">
  <div class="avatar" :class="[ cssClass, { 'shadowed' : shadowed } ]" :style="style">
    <span class="avatar__initials">{{ initials }}</span>
  </div>
</template>

<script>
// Thanks to https://codepen.io/morgoe/pen/QbdQwY

const numColors = 3

export default {
  props: {
    name: {
      type: String,
      default: 'Guest'
    },
    size: {
      type: Number,
      default: 40
    },
    shadowed: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    style () {
      return {
        width: `${this.size}px`,
      	height: `${this.size}px`,
        fontSize: `${this.size / 2.5}px`
      }
    },
    initials () {
      const initials = this.name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)
      if (!initials) { return '' }
      return initials.join('').substring(0, 2).toUpperCase()
    },
    cssClass () {
      let hash = 0
      const name = this.name
      const nameLength = name.length

			if (nameLength !== 0) {

  			name.split('').forEach(letter => {
  				hash = ((hash << 5) - hash) + letter.charCodeAt(0)
					hash |= 0 // Convert to 32bit integer
  			})
      }

      const colorNumber = Math.abs(hash) % numColors

      return `color-${colorNumber}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/members/_variables.scss';

$black: #4f4f4f;
$red:   #d9566f;
$blue:  #56bcd9;
$green: #7fbb15;

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: $colorGreyLightish;
  background-color: $colorGrey;

  &.shadowed {
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
  }

  &.color-0 {
		color: $red;
		background: rgba($red, 0.15);
	}

  &.color-1 {
		color: $blue;
		background: rgba($blue, 0.15);
	}

  &.color-2 {
		color: $green;
		background: rgba($green, 0.15);
	}
}

.avatar__initials {
  transform: translateY(.12em);
  // letter-spacing: .5em;
  // text-align: center;
  // outline: solid thin red;
}
</style>
