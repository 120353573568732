<template lang="html">
  <div>
    <template v-if="submitted">
      <p>Thank you for submitting your nomination. It has been forwarded to our member development team.</p>
      <span class="g100-button" @click="reset">Nominate another member</span>
    </template>
    <template v-else>
      <p>We find that the best new G100 members are those that have been recommended by our existing group. If you would like to nominate an executive to join G100, please let us know.</p>
      <form @submit.prevent="validateBeforeSubmit">
        <form-field label="First Name" v-model="form.firstName" name="firstName" v-validate="'required'" autocomplete="given-name" />
        <form-field label="Last Name" v-model="form.lastName" name="lastName" v-validate="'required'" autocomplete="family-name" />
        <form-field label="Company" v-model="form.company" name="company" v-validate="'required'" autocomplete="organization" />
        <form-field label="Email Address" v-model="form.emailAddress" name="emailAddress" v-validate="'required|email'" autocomplete="email" />
        <div class="field actions">
          <button class="g100-button is-fullwidth" type="submit">Nominate</button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  data () {
    return {
      submitted: false,
      form: {
        firstName: null,
        lastName: null,
        company: null,
        emailAddress: null
      }
    }
  },
  methods: {
    reset () {
      this.submitted = false
      this.form = {
        firstName: null,
        lastName: null,
        company: null,
        emailAddress: null
      }
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit()
          return
        }

        alert('Please correct errors')
      })
    },
    submit () {
      this.$apollo.mutate({
        mutation: gql`mutation ($memberNomination: MemberNominationInput!) {
          createMemberNomination(memberNomination: $memberNomination) {
            id
          }
        }`,
        // Parameters
        variables: {
          memberNomination: this.form
        }
      }).then(data => {
        this.submitted = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
p + form {
  margin-top: 24px;
}
</style>
