<template lang="html">
  <!-- <a class="mp-file" :href="file.src" target="_blank">
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm1 5h4.586l-4.586-4.586v4.586z"/></svg>
    <div class="meta">
      <span>{{ file.title || file.name }}</span>
      <span>{{ file.humanType }}</span>
      <span>{{ file.size | humanSize }}</span>
    </div>
  </a> -->

  <a class="g100-button" :href="file.src" target="_blank">
    {{ file.title || file.name }}
    ({{ file.size | humanSize }})
  </a>
</template>

<script>
export default {
  props: ['file']
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/members/_variables.scss';

.mp-file {
  display: flex;
  align-items: center;
  color: $colorBlack;
  text-decoration: none;
}

svg {
  flex: 0 0 auto;
  margin-right: 24px;
}

.meta {
  flex: 1 0 auto;
  display: flex;
  margin: -.5em;
}

.meta > span {
  display: inline-flex;
  margin: .5em;
}

.meta > span:first-child {
  flex: 1 0 auto;
  font-family: $fontAvenirMedium;
}
</style>
