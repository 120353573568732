<template>
  <div class="row is-dark">
    <div class="g100-l-2col">
      <div>
        <h1 class="g100-h1">Learn More</h1>
        <p>G100 membership programs bring the outside in, delivering actionable insights that improve business performance. G100 helps companies solve their key business issues on an organizational and individual level. We deeply understand our members and customize their membership to align with their business needs and goals.</p>
        <p>Your information will be shared with our member development team who may contact you regarding G100 membership.</p>
      </div>
      <div id="register-form">
        <register-form :form="form" :program-name="programName" />
      </div>
    </div>
  </div>
</template>

<script>
import RegisterForm from './RegisterForm'

export default {
  components: {
    RegisterForm
  },
  props: {
    programName: {
      type: String,
      default: null
    }
  }
}
</script>
