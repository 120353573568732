<template lang="html">
  <span :class="cssClass" @click="onClick">
    {{ label }}
  </span>
</template>

<script>
export default {
  props: {
    label: String,
    flexible: {
      type: Boolean,
      default: false
    },
    fullwidth: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    busy: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssClass () {
      let mainClass = 'g100-button'
      if (this.bordered) {
        mainClass = 'g100-button--bordered'
      }
      return [
        mainClass,
        { 'is-flexible': this.flexible },
        { 'is-fullwidth': this.fullwidth },
        { 'is-selected': this.selected },
        { 'is-loading': this.busy }
      ]
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
